<template>
  <div class="paylater-list pay-later-product-admin">
    <vx-card class="accordin-card">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Product details <span class="mid-blue text-xl align-top">*</span></h3>
          </div>

          <div class="flex mb-8">
            <div class="input-grp">
              <label for="productName" class="w-full text-xm font-normal">Product name <span class="required-text">*</span></label>
              <vs-input size="large" v-model="product.productName" data-vv-validate-on="blur" data-vv-as="product name" v-validate="'required|max:100'" name="productName" id="productName" />
              <span class="text-danger text-sm" v-show="errors.has('productName')">{{ errors.first("productName") }}</span>
            </div>
            <div class="input-grp">
              <label for="settlementAccounts" class="w-full text-xm font-normal">Settlement account <span class="required-text">*</span></label>
              <vs-select v-model="product.settlementAccount" id="settlementAccounts" name="settlementAccounts" class="w-12/12" data-vv-as="Settlement Account" data-vv-validate-on="blur" v-validate="'required'">
                <vs-select-item :key="index" :value="item._id" :text="item.displayName" v-for="(item, index) in settlementAccounts" />
              </vs-select>
              <span class="text-danger text-sm" v-show="errors.has('settlementAccounts')">{{ errors.first("settlementAccounts") }}</span>
            </div>
          </div>

          <div class="flex">
            <div class="input-grp">
              <label for="productDescription" class="w-full text-xm font-normal flex">
                Product description <span class="required-text pl-2">*</span>
                <vs-tooltip text="Please enter brief description for this product. This description will be presented to merchant.">
                  <img :src="questionIcon" class="pl-2" />
                </vs-tooltip>
              </label>
              <vs-textarea
                v-model="product.productDescription"
                data-vv-validate-on="blur"
                data-vv-as="product description"
                v-validate="'required'"
                name="productDescription"
                id="productDescription"
                class="custom"
              />
              <span class="text-danger text-sm block" v-show="errors.has('productDescription')">{{ errors.first("productDescription") }}</span>
            </div>

            <div class="input-grp">
              <label for="productDescription" class="w-full text-xm font-normal flex">
                Product text <span class="required-text pl-2">*</span>
                <vs-tooltip text="Please enter brief description for this product. This description will be displayed to customer on payment page.">
                  <img :src="questionIcon" class="pl-2" />
                </vs-tooltip>
              </label>
              <vs-textarea
                v-model="product.productText"
                data-vv-validate-on="blur"
                data-vv-as="product text"
                v-validate="'required'"
                name="productText"
                id="productText"
                class="custom"
              />
              <span class="text-danger text-sm block" v-show="errors.has('productText')">{{ errors.first("productText") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Product configuration</h3>
          </div>
          <vs-row>
            <vs-col class="w-auto">
              <div class="flex">
                <p class="mt-8 mb-4 font-light darkBlue">Allow merchant to schedule disbursement</p>
              </div>
              <div class="flex">
                <vs-switch v-model="product.allowDisbursementDateOverride" name="scheduleDisbursement" />
                <p class="ml-3">{{ product.allowDisbursementDateOverride ? "ON" : "OFF" }}</p>
              </div>
            </vs-col>
          </vs-row>
          <vs-row class="mt-8" v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType)">
            <vs-col class="lg:mr-5 md:mr-5 w-auto">
              <div class="radio-card h-full" @click="changeProductConfig('THREE_PARTY')" :class="{ active: product.productConfigType === 'THREE_PARTY' }">
                <vs-row class="justify-between rounded-b-lg">
                  <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">3-party product</vs-col>
                  <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center margin-break">
                    <vs-radio v-model="product.productConfigType" vs-value="THREE_PARTY" vs-name="productConfigType" name="productConfigType" id="productConfigType" :disabled="productEdit"></vs-radio>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
            <vs-col class="w-auto" v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType)">
              <div class="radio-card h-full" @click="changeProductConfig('TWO_PARTY')" :class="{ active: product.productConfigType === 'TWO_PARTY' }">
                <vs-row class="justify-between rounded-b-lg">
                  <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">2-party product</vs-col>
                  <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center margin-break">
                    <vs-radio v-model="product.productConfigType" vs-value="TWO_PARTY" vs-name="productConfigType" name="productConfigType" id="productConfigType" :disabled="productEdit"></vs-radio>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
          <vs-row v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType)">
            <vs-col class="w-auto">
              <div class="flex">
                <p class="mt-8 mb-4 font-light darkBlue">Show balance and transactions in merchant portal</p>
              </div>
              <div :class="[product.productConfigType === 'TWO_PARTY' ? 'flex disabled' : 'flex']">
                <vs-switch v-model="product.showTransactionBalance" name="transactionBalance" :class="product.productConfigType === 'TWO_PARTY' ? 'disabled' : ''"></vs-switch>
                <p class="ml-3">{{ product.showTransactionBalance ? "ON" : "OFF" }}</p>
              </div>
            </vs-col>
          </vs-row>
          <vs-row v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType)">
            <vs-col class="w-auto">
              <div class="flex">
                <p class="mt-8 mb-4 font-light darkBlue">Introducer use only</p>
              </div>
              <div class="flex">
                <vs-switch v-model="product.introducerUseOnly" name="introducerUseOnly" />
                <p class="ml-3">{{ product.introducerUseOnly ? "ON" : "OFF" }}</p>
              </div>
            </vs-col>
          </vs-row>
          <vs-row v-if="displayMerchantTopUp">
            <vs-col vs-w="6" vs-sm="6" vs-lg="6" vs-xs="6">
              <p class="mt-8 mb-4 font-light darkBlue">Merchant top up</p>
              <div class="flex">
                <vs-switch v-model="product.merchantTopUp.isEnabled" name="merchantTopUp" />
                <p class="ml-3">{{ product.merchantTopUp.isEnabled ? "ON" : "OFF" }}</p>
              </div>
              <div class="mt-4" v-if="product.merchantTopUp.isEnabled">
                <div>
                  <label for="topUpDescription" class="w-full text-base font-light">Enter instructions for merchant <span class="required-text">*</span></label>
                  <vs-textarea
                    v-model="product.merchantTopUp.description"
                    data-vv-validate-on="blur"
                    data-vv-as="merchant top up instruction"
                    v-validate="'required'"
                    name="topUpDescription"
                    id="topUpDescription"
                    class="custom-texteditor"
                  />
                  <span class="text-danger text-sm block pb-3" v-show="errors.has('topUpDescription')">{{ errors.first("topUpDescription") }}</span>
                </div>
                <div>
                  <label for="topUpFee" class="w-full text-base font-light">Minimum fee for a top up <span class="required-text">*</span></label><br/>
                  <money
                    name="topUpFee"
                    id="topUpFee"
                    v-bind="money2"
                    v-validate="'required|min_value:1'"
                    v-model="product.merchantTopUp.feeAmount"
                    data-vv-as="minimum top up fee amount"
                    class="mb-2"
                    :precision="2"
                  ></money><br/>
                  <span class="text-danger text-sm py-3" v-show="errors.has('topUpFee')">{{ errors.first("topUpFee") }}</span>
                </div>
              </div>
            </vs-col>
          </vs-row>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Disbursement settings</h3>
          </div>
          <div class="flex justify-between">
            <div class="input-grp mr-6 ds-wrapper segmented-btn-product">
              <label for="settlementAccounts" class="w-full text-xm font-normal">Disburse to </label>
              <SegmentedButtons @segmented-buttons="handleSegmentedButtons" :buttons="segmentButton" class="mt-2" :size="'small'"> </SegmentedButtons>
            </div>
            <div class="w-2/3" v-if="disbursement.disburseTo.value =='external'">
              <p class="w-full text-xm font-light darkBlue">Enabled to edit disbursement account:</p>
              <div class="flex mt-2">
                <vs-switch v-model="disbursement.editDisbursement.customer" name="customerCanEditDisbursement" @change="validateDisbursement" :disabled="product.productConfigType === 'TWO_PARTY'"></vs-switch>
                <p class="ml-5">3PP Customer</p>
              </div>
              <div class="flex mt-2">
                <vs-switch v-model="disbursement.editDisbursement.merchant" name="merchantCanEditDisbursement" @change="validateDisbursement"></vs-switch>
                <p class="ml-5">Merchant / 2PP Borrower</p>
              </div>
              <div class="flex mt-2">
                <vs-switch v-model="disbursement.editDisbursement.introducer" name="introducerCanEditDisbursement" @change="validateDisbursement"></vs-switch>
                <p class="ml-5">Introducer</p>
              </div>
              <span class="text-danger text-sm block py-3" v-show="errors.has('editDisbursement')">{{ errors.first("editDisbursement") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Amount limits <span class="mid-blue text-xl align-top">*</span></h3>
          </div>
          <div class="flex">
            <div class="input-grp">
              <label for="minimumAmount" class="w-full text-xm font-normal">Minimum amount <span class="required-text">*</span></label>
              <money
                name="minimumAmount"
                id="minimumAmount"
                v-bind="money2"
                v-validate="'required||min_value:1'"
                v-model="product.minimumAmount"
                data-vv-as="minimum amount"
              ></money>
              <span class="text-danger text-sm block py-3" v-show="errors.has('minimumAmount')">{{ errors.first("minimumAmount") }}</span>
            </div>
            <div class="input-grp">
              <label for="maximumAmount" class="w-full text-xm font-normal">Maximum amount <span class="required-text">*</span></label>
              <money
                name="maximumAmount"
                id="maximumAmount"
                v-bind="money2"
                v-validate="'required||min_value:1||greaterThan'"
                v-model="product.maximumAmount"
                data-vv-as="maximum amount"
              ></money>
              <span class="text-danger text-sm block py-3" v-show="errors.has('maximumAmount')">{{ errors.first("maximumAmount") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <vx-card class="accordin-card mt-5" v-if="this.$route.query.productType === 'B2C_BNPL'">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">
              Schedule <span class="mid-blue text-xl align-top">*</span>
            </h3>
          </div>
          <div class="my-8">
            <label for="maxDuration" class="w-full font-light text-base">Max duration (days)</label>
            <p class="block text-base mt-3">{{ product.maxDuration }} days</p>
          </div>

          <div class="flex flex-wrap">
            <div class="input-grp">
              <label for="paymentFrequency" class="text-xm font-normal w-full">Payment frequency</label>
              <div class="flex flex-col gap-y-2">
                <vs-select
                  v-model="product.paymentFrequency"
                  id="paymentFrequency"
                  name="paymentFrequency"
                  class="w-4/12"
                  v-validate="this.$route.query.productType === 'B2C_BNPL' ? 'required' : ''"
                  data-vv-as="payment frequency"
                >
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in frequency" />
                </vs-select>
                <span class="text-danger text-sm block py-3" v-show="errors.has('paymentFrequency')" >{{ errors.first("paymentFrequency") }}</span>
              </div>
            </div>

            <div class="input-grp">
              <label for="noOfPayments" class="text-xm font-normal w-full">Number of payments</label>
              <div class="flex flex-col">
                <money
                  name="noOfPayments"
                  id="noOfPayments"
                  v-bind="numeric"
                  data-vv-as="no of payments"
                  class="w-full py-3"
                  v-model="product.noOfPayments"
                ></money>
              </div>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5" v-else>
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Schedule <span class="mid-blue text-xl align-top">*</span></h3>
          </div>

          <div class="flex flex-wrap">
            <div class="input-grp">
              <label for="maximumDuration" class="text-xm font-normal w-full">Maximum duration <span class="required-text">*</span></label>
              <p class="block text-base mt-3">{{ product.maxDuration }}</p>
              <span class="text-danger text-sm" v-show="errors.has('maximumDuration')">{{ errors.first("maximumDuration") }}</span>
            </div>
            <div class="input-grp">
              <label for="initialPeriod" class="w-full text-xm font-normal">Initial period <span class="required-text">*</span></label>
              <div class="flex gap-3 items-center">
                <money
                  name="initialPeriod"
                  id="initialPeriod"
                  v-bind="numeric"
                  v-validate="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType) ? 'required||min_value:1' : ''"
                  class="vs-input--input"
                  v-model="product.initialPeriod"
                  data-vv-as="initial period"
                ></money>
                <span>days</span>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('initialPeriod')">{{ errors.first("initialPeriod") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Payment option <span class="mid-blue text-xl align-top">*</span> </h3>
          </div>
          <vs-row>
            <vs-col class="lg:mr-5 md:mr-5 w-auto">
              <div class="mb-1">
                <p class="mb-2">Auto-debit payment method</p>
                <vs-switch v-model="product.autoDebit" @click="changeDishonourFee" name="autoDebit"></vs-switch>
              </div>
            </vs-col>
          </vs-row>
          <vs-row v-if="product.productConfigType == 'THREE_PARTY'">
            <vs-col class="lg:mr-5 md:mr-5 w-auto mt-2">
              <div class="flex">
                <p class="my-4">Payment methods available on payment page</p>
              </div>
              <div class="flex flex-wrap gap-4">
                <div>
                  <div class="selection-card" :class="[{ selected: product.paymentMethods.paymentPage.includes('CARD') }, !product.autoDebit ? 'disabled' : '' ]">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="CARD" v-model="product.paymentMethods.paymentPage" :class="!product.autoDebit ? 'disabled' : ''" @change="validatePaymentMethods('CARD')">Card</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="selection-card" :class="[{ selected: product.paymentMethods.paymentPage.includes('DIRECT_DEBIT') }, !product.autoDebit ? 'disabled' : '' ]">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="DIRECT_DEBIT" v-model="product.paymentMethods.paymentPage" :class="!product.autoDebit ? 'disabled' : ''" @change="validatePaymentMethods('DIRECT_DEBIT')">Direct debit</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span class="text-danger text-sm block mt-1" v-show="errors.has('paymentMethod')">{{ errors.first("paymentMethod") }}</span>
            </vs-col>
          </vs-row>
          <vs-row v-if="product.productConfigType == 'THREE_PARTY'">
            <vs-col class="lg:mr-5 md:mr-5 w-auto mt-2">
              <div class="flex">
                <p class="my-4">Payment methods available on customer portal</p>
              </div>
              <div class="flex flex-wrap gap-4">
                <div>
                  <div class="selection-card disabled" :class="{ selected: product.paymentMethods.customerPortal.includes('CARD') }">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="CARD" v-model="product.paymentMethods.customerPortal" class="disabled">Card</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="selection-card disabled" :class="{ selected: product.paymentMethods.customerPortal.includes('DIRECT_DEBIT') }">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="DIRECT_DEBIT" v-model="product.paymentMethods.customerPortal" class="disabled">Direct debit</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="selection-card" :class="[{ selected: product.paymentMethods.customerPortal.includes('EFT') }, !product.autoDebit ? 'disabled' : '' ]">
                    <div class="selection-card-header">
                      <div class="selection-option">
                        <vs-checkbox vs-value="EFT" v-model="product.paymentMethods.customerPortal" :class="!product.autoDebit ? 'disabled' : ''">EFT</vs-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-col>
          </vs-row>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Pay now settings</h3>
          </div>
          <div>
            <p class="mb-4 font-light darkBlue">Pay now via card & EFT</p>
            <div :class="[product.productConfigType === 'TWO_PARTY' ? 'flex disabled' : 'flex']">
              <vs-switch v-model="product.isPayNow" name="isPayNow" :class="disablePaynow"></vs-switch>
              <p class="ml-3">{{ product.isPayNow ? "Enabled" : "Disabled" }}</p>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Repayment details</h3>
          </div>
          <div class="flex mb-8">
            <div class="input-grp">
              <label for="messageOne" class="w-full text-xm font-normal">Message1</label>
              <vs-input size="large" v-model="product.messageOne" name="messageOne" placeholder="Optional" />
            </div>
            <div class="input-grp">
              <label for="messageTwo" class="w-full text-xm font-normal">Message2</label>
              <vs-input size="large" v-model="product.messageTwo" name="messageTwo" placeholder="Optional" />
            </div>
          </div>
          <div class="flex mb-8">
            <div class="input-grp">
              <label for="contactEmail" class="w-full text-xm font-normal">Contact email</label>
              <vs-input size="large" v-model="product.contactEmail" v-validate="'email'" data-vv-as="contact email" data-vv-validate-on="blur" name="contactEmail" placeholder="Optional" />
              <span class="text-danger text-sm mt-1" v-show="errors.has('contactEmail')">{{ errors.first("contactEmail") }}</span>
            </div>
            <div class="input-grp">
              <div>
                <label class="label">Contact phone</label>
                <the-mask
                  class="vs-inputx vs-input--input large"
                  :mask="[checkPhoneNumber]"
                  v-model="product.contactNumber"
                  masked
                  name="contactNumber"
                  ref="contactNumber"
                  v-validate="'phoneFormat'"
                />
                <span class="text-danger text-sm" v-show="errors.has('contactNumber')">{{ errors.first("contactNumber") }}</span>
              </div>
            </div>
          </div>
          <div class="flex mb-4">
            <div class="input-grp">
              <label for="assignmentText" class="w-full text-xm font-normal">Assignment text</label>
              <vs-textarea v-model="product.assignmentText" name="assignmentText" class="custom-textarea" id="custom-textarea" @input="auto_grow('custom-textarea')" />
            </div>
          </div>
          <div class="flex mb-8">
            <div class="input-grp">
              <label for="repaymentBy" class="w-full text-xm font-normal">Repayment by (default)</label>
              <vs-select v-model="product.repaymentBy" id="repaymentBy" name="repaymentBy">
                <vs-select-item value="" text="Select option" />
                <vs-select-item :key="index" :value="item._id" :text="item.title" v-for="(item, index) in repaymentByList" />
              </vs-select>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Fee model<span class="mid-blue text-xl align-top">*</span></h3>
          </div>
          <div>
            <vs-row>
              <vs-col class="lg:mr-5 md:mr-5 w-auto">
                <div class="radio-card h-full" @click="product.feeModel = 'MSF_INCLUDED'" :class="{ active: product.feeModel === 'MSF_INCLUDED' }">
                  <vs-row class="justify-between">
                    <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Deducted</vs-col>
                    <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center margin-break">
                      <vs-radio v-model="product.feeModel" vs-value="MSF_INCLUDED" vs-name="feeModel" name="feeModel" id="feeModel"></vs-radio>
                    </vs-col>
                  </vs-row>
                  <vs-row>
                    <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                      <span class="radio-info">Service fee will be deducted from the amount to be disbursed</span>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
              <vs-col class="w-auto">
                <div class="radio-card h-full" @click="product.feeModel = 'MSF_ADDED'" :class="{ active: product.feeModel === 'MSF_ADDED' }">
                  <vs-row class="justify-between">
                    <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Added</vs-col>
                    <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center margin-break">
                      <vs-radio v-model="product.feeModel" vs-value="MSF_ADDED" vs-name="feeModel" name="feeModel" id="feeModel"></vs-radio>
                    </vs-col>
                  </vs-row>
                  <vs-row>
                    <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                      <span class="radio-info">Service fee will be added to the borrowing and incorporated into scheduled payments</span>
                    </vs-col>
                  </vs-row>
                </div>
              </vs-col>
              <vs-col vs-w="12">
                <span class="text-danger text-sm mt-1" v-show="errors.has('feeModel')">{{ errors.first("feeModel") }}</span>
              </vs-col>
            </vs-row>
          </div>
          <vs-row class="mb-5">
            <div class="input-grp mt-8">
              <label for="msfRate" class="w-full text-xm font-normal">Service fee (%) <span class="mid-blue text-xl align-top">*</span></label>
              <money
                name="msfRate"
                id="msfRate"
                v-bind="money"
                v-validate="'required||decimalValidation'"
                v-model="product.msf"
                data-vv-as="msf rate"
              ></money>
              <span class="text-danger text-sm py-3" v-show="errors.has('msfRate') && fields.msfRate.touched">{{ errors.first("msfRate") }}</span>
            </div>
            <div class="input-grp mt-8">
              <label for="minimumMsfFeeAmount" class="w-full text-xm font-normal">Minimum fee amount <span class="required-text">*</span></label>
                <money
                  name="minimumMsfFeeAmount"
                  id="minimumMsfFeeAmount"
                  v-bind="money2"
                  class="vs-input--input"
                  v-model="product.minimumMsfFeeAmount"
                  data-vv-validate-on="blur"
                  data-vv-as="minimum fee amount"
                ></money>
                <span class="text-danger text-sm block" v-show="errors.has('minimumMsfFeeAmount')">{{ errors.first("minimumMsfFeeAmount") }}</span>
            </div>
          </vs-row>
          <vs-row v-if="showDishonourBox" class="flex-col">
            <h5 class="h4 mt-5 text-base">Direct debit transaction dishonour fee</h5>
            <hr class="vs-divider mt-0 mb-8">
            <div class="flex mb-8">
              <vs-switch v-model="product.isDishonourFeeActive" @click="changeDishonourFee" class="mr-4" /> Charge dishonour fee
            </div>
            <div v-if="showDishonourFee">
              <label for="dishonourFee" class="w-full text-xm font-normal block">Dishonour fee <span class="mid-blue text-xl align-top">*</span></label>
              <money name="dishonourFee" id="dishonourFee" precision="2" v-bind="money2" v-validate="'required'" v-model="product.dishonourFee" data-vv-as="dishonour fee"></money>
              <p class="text-danger text-sm py-3" v-show="errors.has('dishonourFee')">{{ errors.first("dishonourFee") }}</p>
            </div>
          </vs-row>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5" v-if="isBNPO">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Automated extension fee<span class="mid-blue text-xl align-top">*</span></h3>
          </div>
          <div>
            <p>Add automated extension fees to be debited throughout the life of a PR</p>
            <div class="flex mt-4">
              <vs-switch v-model="product.extension.isEnabledExtension" name="isEnabledExtension" @change="changeExtension"></vs-switch>
              <p class="ml-3">{{ product.extension.isEnabledExtension ? "Enabled" : "Disabled" }}</p>
            </div>
            <div class="mt-4" v-if="product.extension.isEnabledExtension">
              <div>
                <label for="frequencyType" class="w-full text-xm font-normal">Frequency <span class="required-text">*</span></label>
                <vs-row class="mt-2 product-radio">
                  <vs-radio v-model="product.extension.frequency" vs-value="WEEKLY" vs-name="frequencyType">Weekly</vs-radio> <br />
                  <vs-radio v-model="product.extension.frequency" vs-value="FORTNIGHTLY" vs-name="frequencyType" class="ml-8">Fortnightly</vs-radio> <br />
                  <vs-radio v-model="product.extension.frequency" vs-value="MONTHLY" vs-name="frequencyType" class="ml-8">Monthly</vs-radio> <br />
                </vs-row>
              </div>
              <div>
                <label for="maxOccurrences" class="w-full text-xm font-normal">Max occurrences <span class="required-text">*</span></label>
                <div class="flex gap-3 items-center mb-4">
                  <vs-input :type="'number'" size="large" v-model="product.extension.maxOccurrences" data-vv-validate-on="blur" data-vv-as="maximum occurrences" v-validate="'required|min_value:1'" name="maxOccurrences"
                    id="maxOccurrences" @keypress="isNumber($event)" />
                  <span>the number of times we run this process to add a fee, min “1”</span>
                </div>
                <span class="text-danger text-sm block pb-3" v-if="errors.has('maxOccurrences')">{{ errors.first("maxOccurrences") }}</span>
              </div>
              <div>
                <label for="extensionRate" class="w-full text-xm font-normal">Rate (%) <span class="required-text">*</span></label>
                <div class="flex gap-3 items-center mb-4">
                  <money
                    name="extensionRate"
                    id="extensionRate"
                    v-bind="money"
                    v-validate="'required|decimalValidation'"
                    v-model="product.extension.fee"
                    data-vv-as="extension rate"
                  ></money>
                </div>
                <span class="text-danger text-sm block pb-3" v-if="errors.has('extensionRate')">{{ errors.first("extensionRate") }}</span>
              </div>
              <div>
                <label for="startDaysAfter" class="w-full text-xm font-normal">Start charging this fee <span class="required-text">*</span></label>
                <div class="flex gap-3 items-center mb-4">
                  <vs-input type="number" size="large" v-model="product.extension.startDaysAfter" data-vv-validate-on="blur" data-vv-as="starting days" v-validate="'required|min_value:1'" name="startDaysAfter" id="startDaysAfter" />
                  <span>days after PR opened/activated</span>
                </div>
                <span class="text-danger text-sm block pb-3" v-if="errors.has('startDaysAfter')">{{ errors.first("startDaysAfter") }}</span>
              </div>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <!--------------- custom fields section -------------------------->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item ref="productFieldCollapse" open>
          <div slot="header">
            <h3 class="font-normal">Product fields</h3>
          </div>
          <p>You can create custom form fields to collect information related to this payment request.</p>
          <custom-fields v-if="customFieldBind && planReady" v-on:savedCustomField="pushToCustomFields($event)" :customFieldValue="customFieldList"></custom-fields>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion >
        <vs-collapse-item ref="productExtraConditionCollapse" open>
          <div slot="header">
            <h3 class="font-normal">Extra conditions</h3>
          </div>

          <p class="mb-10">
            You can apply condition(s) to limit the total amount on a payment
            request based on the value input in a specified product field. You
            can also configure the error message merchant sees when the rule is
            breached.
          </p>

          <div class="w-full my-5" v-for="(value, index) in extraConditionsList" :key="index">
            <div class="extra-condition">
              <div class="flex justify-between">
                <h5 class="text-xl font-normal">Extra condition {{ index + 1 }}</h5>
                <span class="lg:hidden">
                  <trash-2-icon @click="removeExtraCondition(index)" class="cursor-pointer"></trash-2-icon>
                </span>
              </div>

              <vs-divider class="mb-10"></vs-divider>

              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="5" class="mb-4 md:mb-8">
                  <label class="w-full text-base font-light">Select extra condition to be applied</label>
                  <vs-select v-model="value.criteria" id="extraConditionCriteria" :name="'extraConditionCriteria.'+index" class="w-full" data-vv-as="extra condition" v-validate="'required'">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in extraConditionsCriteria(value.criteria)" />
                  </vs-select>
                  <span class="text-danger text-sm block py-3" v-show="errors.has('extraConditionCriteria.'+index)">{{ errors.first('extraConditionCriteria.'+index) }}</span>
                </vs-col>
                <vs-col vs-w="12" vs-sm="12" vs-lg="5" class="mb-4 md:mb-8">
                  <label for="extraConditionCriteriaProductField" class="w-full flex text-base font-light">Product field <span class="required-text">*</span>
                    <popper
                      v-if="value.criteria == 'PRODUCT_FIELD_AS_DUE_DATE'"
                      trigger="hover"
                      :options="{
                        placement: 'top',
                        modifiers: { offset: { offset: '100px, 2px' } }
                      }">
                      <div class="popper text-left py-3 px-4 w-64">
                        <p class="text-xs text-grey">Products fields must meet the following criteria to be set as due date:</p>
                        <ul class="text-xs list-disc list-outside ml-5 mb-1 text-grey">
                          <li>date field</li>
                          <li>marked as required to send request</li>
                        </ul>
                      </div>
                      <span slot="reference"><img :src="questionIcon" class="pl-2 cursor-pointer"></span>
                    </popper>
                  </label>
                  <vs-select v-model="value.productField" id="extraConditionProductField" :name="'extraConditionCriteriaProductField.'+index" class="w-12/12" data-vv-as="product field" v-validate="'required'">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in productFieldsForExtraConditions(value.criteria)" />
                  </vs-select>
                  <span class="text-danger text-sm block py-3" v-show="errors.has('extraConditionCriteriaProductField.'+index)">{{ errors.first('extraConditionCriteriaProductField.'+index) }}</span>
                </vs-col>
                <vs-col class="hidden lg:flex" vs-w="12" vs-sm="2" vs-lg="2" vs-type="flex" vs-justify="end" vs-align="start">
                  <trash-2-icon @click="removeExtraCondition(index)" class="cursor-pointer"></trash-2-icon>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="5" class="mb-4">
                  <div class="input-grp relative" >
                    <div v-if="value.criteria == 'MAX_PERCENT_OF_PRODUCT_FIELD'">
                      <label for="extraConditionMaxPercent" class="w-full text-base block">Maximum % <span class="required-text">*</span></label>
                      <money
                        name="extraConditionMaxPercent"
                        id="extraConditionMaxPercent"
                        v-bind="money"
                        v-validate="'required||decimalValidation'"
                        class="vs-input--input w-full"
                        v-model="value.maxPercentage"
                        data-vv-as="extra condition max percent"
                      ></money>
                      <span class="text-danger text-sm block" v-show="errors.has('extraConditionMaxPercent')">{{ errors.first("extraConditionMaxPercent") }}</span>
                    </div>
                    <div v-if="value.criteria == 'PRODUCT_FIELD_AS_DUE_DATE'">
                      <label class="w-full text-base font-light">Override date with max product duration</label>
                      <vs-switch v-model="value.overrideDate" name="overrideDate" />
                    </div>
                  </div>
                </vs-col>
                <vs-col vs-w="12" vs-sm="12" vs-lg="5" class="mb-4">
                  <div class="" v-if="!value.overrideDate">
                    <label for="extraConditionErrorMsg" class="w-full text-base font-light">Error message</label>
                    <vs-textarea
                      :name="'extraConditionErrorMsg.'+index"
                      id="extraConditionErrorMsg"
                      v-model="value.errorMsg"
                      data-vv-as="extra condition error message"
                      v-validate="'required'"
                    ></vs-textarea>
                    <span class="text-danger text-sm block py-3" v-show="errors.has('extraConditionErrorMsg.'+index)">{{ errors.first('extraConditionErrorMsg.'+index) }}</span>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </div>
          <vs-button v-if="showAddExtraConditionOption" v-round size="medium" class="mt-4 flex items-center justify-center only-border-btn" @click="addExtraCondition">
            <plus-icon size="1.5x" class="button-icon"></plus-icon> Add condition
          </vs-button>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5" v-if="isBNPO">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">
              Auto direct debit days <span class="mid-blue text-xl align-top">*</span>
            </h3>
          </div>
          <div class="flex">
            <div class="input-grp">
              <label for="minimumAmount" class="w-full text-xm font-normal">Number of days after due date that a DD will occur <span class="required-text">*</span></label>
              <money
                name="gracePeriod"
                id="gracePeriod"
                v-bind="money3"
                v-validate="'required||min_value:0'"
                v-model="product.gracePeriod"
                data-vv-as="day"
              ></money>
              <span class="text-danger text-sm block py-3" v-show="errors.has('gracePeriod')">{{ errors.first("gracePeriod") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

   <!-- Manual Assessment Block -->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Product-based manual assessment</h3>
          </div>
          <div class="">
            <div class="flex">
              <vs-switch v-model="product.isManualAssessment" size="medium" />
              <p class="ml-6">All requests required to be manually assessed</p>
            </div>
            <div class="mt-8" v-if="product.isManualAssessment">
              <p class="mb-5">Please enter the email address for receiving manual assessment notifications for this product.</p>
              <label for="msfRate" class="w-full mb-0 text-base">Email address</label>
              <vs-input
                size="large"
                v-model="product.manualAssessmentEmail"
                data-vv-as="email"
                v-validate="product.isManualAssessment == true ? 'email' : ''"
                name="manualAssessmentEmail"
                placeholder="Optional"
              />
              <span class="text-danger text-sm py-3" v-show="errors.has('manualAssessmentEmail')">{{ errors.first("manualAssessmentEmail") }}</span>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <!-- Manual Assessment Block Ends -->

    <!-- Criteria-Based Manual Assessment Block -->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Criteria-based manual assessment</h3>
          </div>
          <div>

            <div class="pb-4">
              <p class="mb-5">Please enter the email address for receiving manual assessment notifications for this product.</p>
              <label for="msfRate" class="w-full mb-0 text-base">Email address</label>
              <vs-input
                size="large"
                v-model="product.criteriaAssessmentEmail"
                data-vv-as="email"
                v-validate="product.criteriaAssessmentEmail == true ? 'email' : ''"
                name="criteriaAssessmentEmail"
                placeholder="Optional"
              />
              <span class="text-danger text-sm py-3" v-show="errors.has('criteriaAssessmentEmail')">{{ errors.first("criteriaAssessmentEmail") }}</span>
            </div>
            <hr class="my-4">
            <div class="mt-6">
              <vs-row v-for="(criteria, index) in manualAssessmentCriteria" :key="index">
                <vs-col vs-w="11" vs-lg="4" vs-md="5" vs-sm="11" vs-xs="11" class="py-3">
                  <vs-select v-model="criteria.criteria" :name="'criteria.'+index" class="w-full" data-vv-as="criteria" v-validate="'required'">
                    <vs-select-item :key="key" :value="item.value" :text="item.title" v-for="(item, key) in defaultCriteria(criteria.criteria)" />
                  </vs-select>

                  <span class="text-danger text-sm py-3" v-show="errors.has('criteria.'+index)">{{ errors.first("criteria."+index) }}</span>
                  <div v-if="criteria.productField && criteria.criteria == 'DROPDOWN_HAS_VALUE'">
                    <ul class="mt-3">
                      <li v-for="(dropdownItem, key) in getDropdownData(criteria.productField)" :key="key" class="py-1">
                        <vs-checkbox v-model="criteria.dataList" :vs-value="dropdownItem" :name="'dataList.'+index" v-validate="'required'" data-vv-as="data list">{{dropdownItem}}</vs-checkbox>
                      </li>
                    </ul>
                    <span class="text-danger text-sm py-3" v-show="errors.has('dataList.'+index)">{{ errors.first("dataList."+index) }}</span>
                  </div>
                  <div v-if="criteria.criteria == 'AMOUNT_GREATER_THAN_PERCENTAGE'">
                    <label>Manual assessment if greater than %</label>
                    <money
                      :name="'value.'+index"
                      :id="'value.'+index"
                      v-bind="money2Percentage"
                      v-validate="'required||min_value:0.1||max_value:100'"
                      v-model="criteria.value"
                      data-vv-as="percentage"
                      class="w-full"
                      placeholder="Manual assessment if greater than %"
                    />
                    <br />
                    <span class="text-danger text-sm py-3" v-show="errors.has('value.'+index)">{{ errors.first('value.'+index) }}</span>
                  </div>
                </vs-col>
                <vs-col vs-w="1" vs-xl="4" vs-md="2" vs-sm="1" vs-xs="1" class="md:hidden py-3">
                  <trash2-icon size="1.5x" class="button-icon cursor-pointer" @click="removeManualAssessmentCriteria(index)"></trash2-icon>
                </vs-col>
                <vs-col vs-w="11" vs-lg="4" vs-md="5" vs-sm="11" vs-xs="11" class="py-3 px-4">
                  <money
                    v-if="criteria.criteria == 'AMOUNT_GREATER_THAN'"
                    :name="'value.'+index"
                    :id="'value.'+index"
                    v-bind="money2"
                    v-validate="'required||min_value:1'"
                    v-model="criteria.value"
                    data-vv-as="amount"
                    class="w-full"
                  />
                  <!-- THIS SELECT FIELD IS ONLY FOR "DROPDOWN_HAS_VALUE" -->
                  <vs-select
                    v-if="criteria.criteria == 'DROPDOWN_HAS_VALUE'"
                    v-model="criteria.productField"
                    :name="'productField.'+index"
                    class="w-full"
                    data-vv-as="product field"
                    v-validate="'required'"
                  >
                    <vs-select-item v-for="(item, key) in getDropdownFields(criteria.productField)" :key="key" :value="item.label" :text="item.label" />
                  </vs-select>
                  <!-- THIS SELECT FIELD IS ONLY FOR "PRODUCT_FIELD_NOT_POPULATED" -->
                  <vs-select
                    v-if="criteria.criteria == 'PRODUCT_FIELD_NOT_POPULATED'"
                    v-model="criteria.productField"
                    :name="'productField.'+index"
                    class="w-full"
                    data-vv-as="product field"
                    v-validate="'required'"
                  >
                    <vs-select-item v-for="(item, key) in getFieldNotPopulatedList(criteria.productField)" :key="key" :value="item.label" :text="item.label" />
                  </vs-select>
                  <vs-select
                    v-if="criteria.criteria == 'AMOUNT_GREATER_THAN_PERCENTAGE'"
                    v-model="criteria.productField"
                    :name="'productField.'+index"
                    class="w-full"
                    data-vv-as="product field"
                    v-validate="'required'"
                  >
                    <vs-select-item v-for="(item, key) in productFieldsForExtraConditions(criteria.criteria)" :key="key" :value="item.text" :text="item.text" />
                  </vs-select>
                  <span class="text-danger text-sm py-3" v-show="errors.has('productField.'+index)">{{ errors.first('productField.'+index) }}</span>
                  <br>
                  <span class="text-danger text-sm py-3" v-show="criteria.criteria == 'AMOUNT_GREATER_THAN' && errors.has('value.'+index)">{{ errors.first('value.'+index) }}</span>
                </vs-col>
                <vs-col vs-w="1" vs-xl="4" vs-md="2" vs-sm="1" vs-xs="1" class="hidden md:block py-3">
                  <trash2-icon size="1.5x" class="button-icon cursor-pointer" @click="removeManualAssessmentCriteria(index)"></trash2-icon>
                </vs-col>
              </vs-row>
            </div>
            <div>
              <plus-icon size="1.5x" class="button-icon cursor-pointer" @click="addManualAssessmentCriteria"></plus-icon>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <!-- Manual Assessment Block Ends -->

    <!--------------- preview section -------------------------->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Payment page settings</h3>
          </div>
          <vs-row>
            <vs-col vs-w="10">
              <h5>Product card</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a v-if="showSummaryCardResetButton" @click="resetPaymentCard" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
            <div>
              <template v-if="product.productConfigType === 'THREE_PARTY'">
                <div class="ds-wrapper flex items-center gap-x-6 mb-10">
                  <p class="text-body-dark text-lg">Marketing banner</p>
                  <vs-switch v-model="product.marketingBanner" />
                </div>

                <div v-if="product.marketingBanner" class="ds-wrapper">
                  <editor
                    :key="'marketingEditor'"
                    ref="marketingEditor"
                    v-model="product.marketingBannerText"
                    class="quill-editor-max-width"
                    :counter="''"
                    :validator="validatorNew"
                    :editorOption="editorOption"
                    data-vv-as="marketing banner"
                    :validate="'required'"
                    :hasError="errors.has('marketingBannerText')"
                    :errorMessage="errors.first('marketingBannerText')"
                    :name="'marketingBannerText'"
                    id="'marketingBannerText'"
                  >
                  </editor>
                </div>
              </template>

              <div v-if="product.productConfigType === 'TWO_PARTY'">
                <label for="cardTitle" class="w-full text-xm font-normal">Card title</label>
                <vs-input size="large" v-model="product.cardTitle" data-vv-validate-on="blur" data-vv-as="card title" v-validate="'required|min:1|max:60'" name="cardTitle" id="cardTitle" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('cardTitle')">{{ errors.first("cardTitle") }}</span>
              </div>

              <div v-if="this.$route.query.productType === 'B2C_BNPL'">
                <label for="dateLabel" class="w-full text-xm font-normal">Date label</label>
                <vs-input size="large" v-model="product.dateLabel" data-vv-validate-on="blur" data-vv-as="date label" v-validate="'min:1'" name="dateLabel" id="dateLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('dateLabel')">{{ errors.first("dateLabel") }}</span>
              </div>

              <div v-if="this.$route.query.productType === 'B2C_BNPL'">
                <label for="amountLabel" class="w-full text-xm font-normal">Amount label</label>
                <vs-input size="large" v-model="product.amountLabel" data-vv-validate-on="blur" data-vv-as="amount label" v-validate="'min:1'" name="amountLabel" id="amountLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('amountLabel')">{{ errors.first("amountLabel") }}</span>
              </div>
              <div v-else>
                <label for="purchaseAmountLabel" class="w-full text-xm font-normal">Amount label</label>
                <vs-input size="large" v-model="product.purchaseAmountLabel" data-vv-validate-on="blur" data-vv-as="amount label" v-validate="'min:1|max:20'" name="purchaseAmountLabel" id="purchaseAmountLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('purchaseAmountLabel')">{{ errors.first("purchaseAmountLabel") }}</span>
              </div>

              <div v-if="this.$route.query.productType !== 'B2C_BNPL'">
                <label for="feeLabel" class="w-full text-xm font-normal">Fee label</label>
                <vs-input size="large" v-model="product.feeLabel" data-vv-validate-on="blur" data-vv-as="fee label" v-validate="'min:1'" name="feeLabel" id="feeLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('feeLabel')">{{ errors.first("feeLabel") }}</span>
              </div>

              <div  v-if="this.product.productConfigType === 'TWO_PARTY' && this.$route.query.productType !== 'B2C_BNPL'">
                <label for="disbursementLabel" class="w-full text-xm font-normal">Disbursement label</label>
                <vs-input size="large" v-model="product.disbursementLabel" data-vv-validate-on="blur" data-vv-as="disbursement label" v-validate="'min:1|max:20'" name="disbursementLabel" id="disbursementLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('disbursementLabel')">{{ errors.first("disbursementLabel") }}</span>
              </div>

              <div>
                <label for="totalAmountLabel" class="w-full text-xm font-normal">Total label</label>
                <vs-input size="large" v-model="product.totalAmountLabel" data-vv-validate-on="blur" data-vv-as="total label" v-validate="'min:1'" name="totalAmountLabel" id="totalAmountLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('totalAmountLabel')">{{ errors.first("totalAmountLabel") }}</span>
              </div>

              <div v-if="isBNPO && this.product.extension && this.product.extension.isEnabledExtension">
                <label for="configurableFeeText" class="w-full text-xm font-normal">Configurable Fee text</label>
                <div class="available-content-grid w-6/12 ds-wrapper mb-4">
                  <div @click="addText('{{Fee %}}', 'configurableFeeText', 'configurableFeeTextEditor')"><vs-chip color="primary" size="medium" variant="outlined" class="cursor-pointer">Fee %</vs-chip></div>
                  <div @click="addText('{{Fee $ amount}}', 'configurableFeeText', 'configurableFeeTextEditor')"><vs-chip color="primary" size="medium" variant="outlined" class="cursor-pointer">Fee $ amount</vs-chip></div>
                </div>
                <vs-textarea v-model="product.configurableFeeText" name="configurableFeeText" data-vv-as="fee text" ref="configurableFeeTextEditor" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('configurableFeeText')">{{ errors.first("configurableFeeText") }}</span>
              </div>

              <div v-if="this.$route.query.productType === 'B2C_BNPL' && product.feeModel === 'MSF_ADDED'">
                <label for="feeTextLabel" class="w-full text-xm font-normal">Fee text</label>
                <div class="available-content-grid w-6/12 ds-wrapper mb-4">
                  <div @click="addText('{{Fee %}}', 'feeText', 'feeTextEditor')"><vs-chip color="primary" size="medium" variant="outlined" class="cursor-pointer">Fee %</vs-chip></div>
                  <div @click="addText('{{Fee $ amount}}', 'feeText', 'feeTextEditor')"><vs-chip color="primary" size="medium" variant="outlined" class="cursor-pointer">Fee $ amount</vs-chip></div>
                </div>
                <vs-textarea v-model="product.feeText" name="feeText" data-vv-as="fee text" ref="feeTextEditor" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('feeText')">{{ errors.first("feeText") }}</span>
              </div>

              <div v-if="this.$route.query.productType !== 'B2C_BNPL'">
                <label for="message" class="w-full text-xm font-normal">{{ product.productConfigType === "TWO_PARTY" ? "Due date message" : "Message" }}</label>
                <vs-input size="large" v-model="product.message" data-vv-validate-on="blur" data-vv-as="message" v-validate="'min:1'" name="message" id="message" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('message')">{{ errors.first("message") }}</span>
              </div>
            </div>

            <div>
              <template v-if="product.productConfigType === 'TWO_PARTY'">
                <div class="preview pay-later-product-preview">
                  <div class="previewed-item">
                    <div class="paylater-card">
                      <div class="card-title text-xl py-4 px-6 h-auto">{{ product.cardTitle }}</div>
                      <div class="card-details" v-if="this.$route.query.productType === 'B2C_BNPL'">
                        <div class="icon-group">
                          <vs-row class="flex justify-around px-6 py-4 items-center">
                            <div class="flex flex-col justify-center items-center">
                              <img :src="update" alt="update" />
                              <span class="dark-blue text-xs mt-1"> Every</span>
                              <span class="dark-blue text-xs mb-0">{{ payable }}</span>
                            </div>
                            <div class="flex flex-col justify-center items-center">
                              <img :src="playCircle" alt="playcircle" />
                              <span class="dark-blue text-xs mt-1">Starts</span>
                              <span class="dark-blue text-xs mb-0">{{ formatScheduleDate(schedule[0]) }}</span>
                            </div>
                            <div class="flex flex-col justify-center items-center">
                              <img :src="stopCircle" alt="stopcircle" />
                              <span class="dark-blue text-xs mt-1"> Ends after </span>
                              <span class="text-center text-xs dark-blue mb-0">{{ product.noOfPayments }} payments</span>
                            </div>
                          </vs-row>
                        </div>
                        <div class="w-full px-6 pb-6 pt-2">
                          <vs-row vs-w="12">
                            <vs-table :data="schedule" class="w-full summary-table">
                              <template slot="thead">
                                <vs-th><span class="text-base font-medium">{{ this.product.dateLabel }}</span></vs-th>
                                <vs-th class="sec-row-payment"><span class="text-base font-medium">{{ product.amountLabel }}</span></vs-th>
                              </template>
                              <template slot-scope="{ data }">
                                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                  <vs-td :data="data[indextr].value" class="font-light text-base">{{ formatScheduleDate(data[indextr]) }}</vs-td>
                                  <vs-td :data="data[indextr].label" class="sec-row-payment font-light text-base">${{ perInstallmentAmount }}</vs-td>
                                </vs-tr>
                              </template>
                            </vs-table>
                          </vs-row>
                          <vs-divider class="my-2"></vs-divider>
                          <div class="total">
                            <div class="w-full flex justify-between">
                              <p class="text-lg dark-blue">{{ product.totalAmountLabel }}</p>
                              <p class="text-lg dark-blue">$ {{ addZeroes("1000") }}</p>
                            </div>
                            <p class="mt-2 text-sm" v-if="this.product.feeModel === 'MSF_ADDED'">{{ parseContent(product.feeText) }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="card-details" v-else>
                        <div class="flex flex-col m-auto">
                          <div class="grey-container py-4">
                            <p class="dark-blue text-xs text-center">{{ product.message }}</p>
                            <div class="flex items-center m-auto mt-3 justify-center">
                              <calendar-icon size="1.5x"></calendar-icon>
                              <span class="dark-blue ml-2">{{ getMaturityDate() }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="agreement-cont">
                          <div class="flex justify-between m-auto pb-2">
                            <span class="text-base"> {{ product.purchaseAmountLabel }}</span>
                            <span class="text-base"> $ {{ addZeroes("1000") }}</span>
                          </div>
                          <div class="flex justify-between m-auto py-5">
                            <span class="text-base">{{ product.feeLabel }} ({{ product.msf }}%)</span>
                            <span class="text-base">${{ product.feeModel === "MSF_ADDED" ? addZeroes(this.product.msf * 0.01 * 1000) : 0.00 }}</span>
                          </div>
                          <vs-divider class="m-auto my-5"></vs-divider>
                          <div class="total">
                            <div class="flex justify-between m-auto">
                              <p class="text-xl dark-blue">{{ product.disbursementLabel || "Disbursment" }}</p>
                              <p class="text-xl dark-blue">${{ this.product.feeModel === "MSF_ADDED" ? addZeroes(1000) : addZeroes(1000 - (this.product.msf * 0.01 * 1000)) }}</p>
                            </div>
                            <div class="flex justify-between m-auto mt-1">
                              <p class="text-xl dark-blue">{{ product.totalAmountLabel }}</p>
                              <p class="text-xl dark-blue">${{ this.product.feeModel === "MSF_ADDED" ? addZeroes(this.product.msf * 0.01 * 1000 + 1000) : addZeroes(1000) }}</p>
                            </div>
                            <p class="mt-6 text-sm">{{ product.configurableFeeText }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <PreviewCard :product="product" />
              </template>
            </div>
          </div>

          <div>
            <vs-row>
              <vs-col vs-w="10">
                <h5>Explainer</h5>
              </vs-col>
            </vs-row>
            <vs-divider></vs-divider>

            <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
              <div>
                <div class="flex mb-10">
                  <vs-switch v-model="product.explainer.isEnabled" name="isEnabledExplainer" @change="changeExplainer"></vs-switch>
                  <p class="ml-3">{{ product.explainer.isEnabled ? "Enabled" : "Disabled" }}</p>
                </div>
                <div v-if="product.explainer.isEnabled">
                  <div>
                    <label for="explainerHeading" class="w-full text-xm font-normal">Heading</label>
                    <vs-input size="large" v-model="product.explainer.header" data-vv-validate-on="blur" data-vv-as="explainer heading" v-validate="'required'" name="explainerHeading" id="explainerHeading" />
                    <span class="text-danger text-sm block py-3" v-show="errors.has('explainerHeading')">{{ errors.first("explainerHeading") }}</span>
                  </div>
                  <div>
                    <label for="explainerContent" class="w-full text-xm font-normal">Content</label>
                    <vs-textarea
                      v-model="product.explainer.content"
                      data-vv-validate-on="blur"
                      data-vv-as="explainer content"
                      v-validate="'required'"
                      name="explainerContent"
                      id="explainerContent"
                      class="custom-texteditor"
                    />
                    <span class="text-danger text-sm block pb-3" v-show="errors.has('explainerContent')">{{ errors.first("explainerContent") }}</span>
                  </div>
                  <div>
                    <label for="explainerButtonText" class="w-full text-xm font-normal">Button text</label>
                    <vs-input size="large" v-model="product.explainer.buttonText" data-vv-as="button text" v-validate="'required'" name="explainerButtonText" id="explainerButtonText" />
                    <span class="text-danger text-sm block py-3" v-show="errors.has('explainerButtonText')">{{ errors.first("explainerButtonText") }}</span>
                  </div>
                </div>
              </div>
              <div v-if="product.explainer.isEnabled">
                <h5 class="block text-base mb-4">Preview</h5>
                <div class="ds-wrapper preview pay-later-product-preview">
                  <div class="bg-page previewed-item">
                    <div class="paylater-card">
                      <div class="product-page my-16">
                        <div>
                          <h2 class="mt-6 text-primary uppercase text-center">{{ product.explainer.header }}</h2>
                          <div class="mt-6 custom-texteditor">
                            <p class="text-body-dark" v-html="product.explainer.content"></p>
                          </div>
                          <div class="flex flex-wrap justify-center my-16">
                            <vs-button color="primary" size="large" class="w-full max-w-400">{{ product.explainer.buttonText }}</vs-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <vs-row v-if="disbursement.editDisbursement.customer">
            <vs-col vs-w="10">
              <h5>Disbursement account</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a @click="resetDisbursementMethod" v-if="showDisbursementResetButton" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider v-if="disbursement.editDisbursement.customer"></vs-divider>
          <div v-if="disbursement.editDisbursement.customer" class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
            <div>
              <div>
                <label for="cardTitle" class="w-full text-xm font-normal">Section heading</label>
                <vs-input size="large" v-model="disbursement.sectionHeading" data-vv-validate-on="blur" data-vv-as="section heading" v-validate="'required|min:1|max:60'" name="disbursementSectionHeading" id="disbursementSectionHeading" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('disbursementSectionHeading')">{{ errors.first("disbursementSectionHeading") }}</span>
              </div>
              <div>
                <div class="flex ds-wrapper">
                  <label for="dateLabel" class="w-full text-xm font-normal">Message</label>
                  <vs-switch v-model="disbursement.showDescription" class="ml-8" />
                </div>
                <div v-if="disbursement.showDescription">
                  <vs-textarea
                    class="mt-2"
                    v-model="disbursement.description"
                    name="disbursementDescription"
                    v-validate="disbursement.description ? 'required' : ''"
                    data-vv-as="message"
                  />
                  <span class="text-danger text-sm block py-3" v-show="errors.has('disbursementDescription')">{{ errors.first("disbursementDescription") }}</span>
                </div>
              </div>
            </div>

            <div style="max-width:435px;">
              <h5 class="block text-base mb-4">Preview</h5>
              <div class="preview pay-later-product-preview ds-wrapper bg-main bg-full">
                <div class="previewed-item bg-neutral-300">
                  <div class="paylater-card">

                    <vs-col class="ds-wrapper mt-6 mb-6">
                      <v-stepper :steps="steps" v-model="step"></v-stepper>
                    </vs-col>

                    <vs-col vs-md="12" vs-sm="12">
                      <div class="mb-4">
                        <h2 class="text-lg lg:text-xl font-normal text-dark-blue text-center uppercase mb-6">{{ disbursement.sectionHeading }}</h2>
                      </div>
                      <p v-if="disbursement.showDescription" class="mb-8 text-center">{{ disbursement.description }}</p>
                    </vs-col>
                    <vs-col vs-md="6" vs-sm="12" class="max-w-420 ">
                      <div class="flex flex-col gap-y-8 mb-8 ds-wrapper payment-input">
                        <div class="w-full">
                          <vs-input label="Account name" icon="done" icon-after="true" icon-size="small" />
                        </div>
                          <div class="flex flex-row gap-x-6">
                            <div class="w-2/5">
                              <vs-input label="BSB" placeholder="000 - 000" v-mask="'###-###'" icon="done" icon-after="true" icon-size="small" />
                            </div>
                            <div class="w-3/5"><vs-input label="Account number" icon="done" icon-after="true" icon-size="small" /></div>
                          </div>
                      </div>
                    </vs-col>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <vs-row>
            <vs-col vs-w="10">
              <h5>Payment method</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a @click="resetPaymentMethod" v-if="showPaymentPageResetButton" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
            <div>
              <div>
                <label for="cardTitle" class="w-full text-xm font-normal">Section heading</label>
                <vs-input size="large" v-model="paymentPage.paymentSectionHeading" data-vv-validate-on="blur" data-vv-as="section heading" v-validate="'required|min:1|max:60'" name="paymentSectionHeading" id="paymentSectionHeading" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('paymentSectionHeading')">{{ errors.first("paymentSectionHeading") }}</span>
              </div>
              <div>
                <div class="flex ds-wrapper">
                  <label for="dateLabel" class="w-full text-xm font-normal">Message</label>
                  <vs-switch v-model="paymentPage.showPaymentMethodDescription" class="ml-8" />
                </div>
                <div v-if="paymentPage.showPaymentMethodDescription" class="ds-wrapper">
                  <editor
                    :key="'paymentMethodEditor'"
                    ref="paymentMethodEditor"
                    v-model="paymentPage.paymentMethodDescription"
                    :counter="''"
                    class="quill-editor-max-width"
                    :editorOption="editorOption"
                    data-vv-as="paymentMethodDescription"
                    :validate="paymentPage.showPaymentMethodDescription ? 'required' : ''"
                    :hasError="errors.has('paymentMethodDescription')"
                    :errorMessage="errors.first('paymentMethodDescription')"
                    :name="'paymentMethodDescription'"
                    :id="'paymentMethodDescription'"
                  >
                  </editor>
                </div>
              </div>
            </div>
            <div>
              <h5 class="block text-base mb-4">Preview</h5>
              <div class="ds-wrapper preview pay-later-product-preview">
                <div class="bg-page previewed-item">
                  <div class="paylater-card">
                    <div class="product-page my-16">
                      <div>
                        <v-stepper :steps="steps" v-model="paymentMethodStep"></v-stepper>

                        <h2 class="mt-6 text-primary uppercase text-center">{{ paymentPage.paymentSectionHeading }}</h2>

                        <div class="mt-6 text-center" v-if="paymentPage.showPaymentMethodDescription">
                          <p class="text-body-dark" v-html="paymentPage.paymentMethodDescription"></p>
                        </div>
                        <div class="flex flex-row justify-center gap-4 ds-wrapper mt-5">
                          <div class="w-1/2 md:w-2/5">
                            <vs-chip class="w-full tabs-chip chip-active" color="neutral-150" size="large" variant="outlined" type="multiline">
                              <CardPayIcon class="chip-icon" />
                              <div class="chip-text">Card</div>
                            </vs-chip>
                          </div>
                          <div class="w-1/2 md:w-2/5">
                            <vs-chip class="w-full tabs-chip" color="neutral-150" size="large" variant="outlined" type="multiline">
                              <DirectDebitIcon class="chip-icon" />
                              <div class="chip-text">Direct debit</div>
                            </vs-chip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <vs-row class="mt-20">
            <vs-col vs-w="10">
              <h5>Your contact details</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a v-if="showContactDetailMessage" @click="resetContactMessage" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
            <div class="ds-wrapper">
              <label for="contactDetailEditor" class="w-full text-xm font-normal">Message</label>
              <editor
                :key="'contactDetailEditor'"
                ref="contactDetailEditor"
                v-model="product.contactDetailMessage"
                :counter="''"
                class="quill-editor-max-width"
                :editorOption="editorOption"
                data-vv-as="contactDetailMessage"
                :validate="'required'"
                :hasError="errors.has('contactDetailMessage')"
                :errorMessage="errors.first('contactDetailMessage')"
                :name="'contactDetailMessage'"
                :id="'contactDetailMessage'"
              >
              </editor>
            </div>
            <div>
              <h5 class="block text-base mb-4">Preview</h5>
              <div class="ds-wrapper preview pay-later-product-preview">
                <div class="bg-page previewed-item">
                  <div class="paylater-card">
                    <div class="product-page my-16">
                      <div>
                        <v-stepper :steps="steps" v-model="contactStep"></v-stepper>

                        <h2 class="mt-6 text-primary uppercase text-center">your contact details</h2>

                        <div class="mt-6 text-center">
                          <p class="text-body-dark" v-html="product.contactDetailMessage"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <vs-row class="mt-20">
            <vs-col vs-w="10">
              <h5>Required information</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a v-if="product.requiredInformation" @click="resetRequiredInformation" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
            <div class="ds-wrapper">
              <div class="ds-wrapper flex items-center gap-x-6 mb-10">
                <label for="contactDetailEditor" class="w-full text-xm font-normal">Message</label>
                <vs-switch v-model="product.requiredInformation" />
              </div>
              <editor
                v-if="product.requiredInformation"
                :key="'requiredInformationEditor'"
                ref="requiredInformationEditor"
                v-model="product.requiredInformationText"
                :counter="''"
                class="quill-editor-max-width"
                :editorOption="editorOption"
                data-vv-as="requiredInformationText"
                :validate="'required'"
                :hasError="errors.has('requiredInformationText')"
                :errorMessage="errors.first('requiredInformationText')"
                :name="'requiredInformationText'"
                :id="'requiredInformationText'"
              >
              </editor>
            </div>
            <div>
              <h5 class="block text-base mb-4">Preview</h5>
              <div class="ds-wrapper preview pay-later-product-preview">
                <div class="bg-page previewed-item">
                  <div class="paylater-card">
                    <div class="product-page my-16">
                      <div>
                        <v-stepper :steps="steps" v-model="requireInformationStep"></v-stepper>

                        <h2 class="mt-6 text-primary uppercase text-center">Required information</h2>

                        <div class="mt-6 text-center" v-if="product.requiredInformation">
                          <p class="text-body-dark" v-html="product.requiredInformationText"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <vs-row class="mt-20">
            <vs-col vs-w="10">
              <h5>Your identity</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a v-if="showResetIdentity" @click="resetIdentityText" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
            <div class="ds-wrapper">
              <label for="identityEditor" class="w-full text-xm font-normal">Message</label>
              <editor
                :key="'identityEditor'"
                ref="identityEditor"
                v-model="product.identityText"
                :counter="''"
                class="quill-editor-max-width"
                :editorOption="editorOption"
                data-vv-as="identityText"
                :validate="'required'"
                :hasError="errors.has('identityText')"
                :errorMessage="errors.first('identityText')"
                :name="'identityText'"
                :id="'identityText'"
              >
              </editor>
            </div>
            <div>
              <h5 class="block text-base mb-4">Preview</h5>
              <div class="ds-wrapper preview pay-later-product-preview">
                <div class="bg-page previewed-item">
                  <div class="paylater-card">
                    <div class="product-page my-16">
                      <div>
                        <v-stepper :steps="steps" v-model="identityStep"></v-stepper>

                        <h2 class="mt-6 text-primary uppercase text-center">Your identity</h2>

                        <div class="mt-6 text-center">
                          <p class="text-body-dark" v-html="product.identityText"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- PAYMENT SUMMARY -->
          <vs-row>
            <vs-col vs-w="10">
              <h5>Payment summary</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a v-if="showProductSummaryResetButton" @click="resetPaymentSummary" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
            <div>
              <div class="mb-6" v-if="product.productConfigType == 'THREE_PARTY'">
                <label for="summaryHeader" class="w-full text-xm font-normal">Header line</label>
                <vs-input size="large" v-model="product.summaryHeader" name="summaryHeader" id="summaryHeader" />
              </div>
              <div>
                <label for="summaryAmountLabel" class="w-full text-xm font-normal">Amount label</label>
                <vs-input size="large" v-model="product.summaryAmountLabel" data-vv-validate-on="blur" data-vv-as="amount label" v-validate="'required'" name="sumaryAmountLabel" id="sumaryAmountLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('sumaryAmountLabel')">{{ errors.first("sumaryAmountLabel") }}</span>
              </div>
              <div>
                <label for="summaryFeeLabel" class="w-full text-xm font-normal">Fee label</label>
                <vs-input size="large" v-model="product.summaryFeeLabel" data-vv-validate-on="blur" data-vv-as="fee label" v-validate="'required'" name="summaryFeeLabel" id="summaryFeeLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('summaryFeeLabel')">{{ errors.first("summaryFeeLabel") }}</span>
              </div>
              <div>
                <label for="summaryTotalLabel" class="w-full text-xm font-normal">Total label</label>
                <vs-input size="large" v-model="product.summaryTotalLabel" data-vv-validate-on="blur" data-vv-as="total label" v-validate="'required'" name="summaryTotalLabel" id="summaryTotalLabel" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('summaryTotalLabel')">{{ errors.first("summaryTotalLabel") }}</span>
              </div>
              <div v-if="isBNPO && this.product.extension && this.product.extension.isEnabledExtension">
                <label for="summaryConfigurableFeeText" class="w-full text-xm font-normal">Configurable Fee text</label>
                <div class="available-content-grid w-6/12 ds-wrapper mb-4">
                  <div @click="addText('{{Fee %}}', 'summaryConfigurableFeeText', 'summaryConfigurableFeeTextEditor')"><vs-chip color="primary" size="medium" variant="outlined" class="cursor-pointer">Fee %</vs-chip></div>
                  <div @click="addText('{{Fee $ amount}}', 'summaryConfigurableFeeText', 'summaryConfigurableFeeTextEditor')"><vs-chip color="primary" size="medium" variant="outlined" class="cursor-pointer">Fee $ amount</vs-chip></div>
                </div>
                <vs-textarea v-model="product.summaryConfigurableFeeText" name="summaryConfigurableFeeText" data-vv-as="fee text" ref="summaryConfigurableFeeTextEditor" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('summaryConfigurableFeeText')">{{ errors.first("summaryConfigurableFeeText") }}</span>
              </div>
              <div v-if="this.$route.query.productType !== 'B2C_BNPL'">
                <label for="summaryMessage" class="w-full text-xm font-normal">Due date message</label>
                <vs-input size="large" v-model="product.summaryMessage" data-vv-validate-on="blur" data-vv-as="message" v-validate="'required'" name="summaryMessage" id="summaryMessage" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('summaryMessage')">{{ errors.first("summaryMessage") }}</span>
              </div>
              <div>
                <label for="summaryButtonText" class="w-full text-xm font-normal">Button text</label>
                <vs-input size="large" v-model="product.summaryButtonText" data-vv-as="button text" v-validate="'required|max:25'" name="summaryButtonText" id="summaryButtonText" />
                <span class="text-danger text-sm block py-3" v-show="errors.has('summaryButtonText')">{{ errors.first("summaryButtonText") }}</span>
              </div>
            </div>
            <div>
              <payment-summary-preview :product="product" />
            </div>
          </div>

          <vs-row class="mt-20">
            <vs-col vs-w="10">
              <h5>Payment success</h5>
            </vs-col>
            <vs-col vs-w="2" class="ds-wrapper">
              <a v-if="showPaymentSuccessReset" @click="resetPaymentSuccessText" class="no-underline text-interaction-primary flex gap-4">
                <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                Reset to default
              </a>
            </vs-col>
          </vs-row>
          <vs-divider></vs-divider>
          <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-5 h-auto">
            <div>
              <div>
                <div class="mb-6">
                  <label for="paymentSuccessHeading" class="w-full text-xm font-normal">Section heading</label>
                  <vs-input size="large" v-model="product.paymentSuccessHeading" v-validate="'required'" data-vv-as="section heading" name="paymentSuccessHeading" id="paymentSuccessHeading" />
                  <span class="text-danger text-sm block py-3" v-show="errors.has('paymentSuccessHeading')">{{ errors.first("paymentSuccessHeading") }}</span>
                </div>
              </div>
              <div class="ds-wrapper">
                <label for="paymentSuccessEditor" class="w-full text-xm font-normal">Message</label>
                <editor
                  :key="'paymentSuccessEditor'"
                  ref="paymentSuccessEditor"
                  v-model="product.paymentSuccessText"
                  :counter="''"
                  class="quill-editor-max-width"
                  :editorOption="editorOption"
                  data-vv-as="paymentSuccessText"
                  :validate="'required'"
                  :hasError="errors.has('paymentSuccessText')"
                  :errorMessage="errors.first('paymentSuccessText')"
                  :name="'paymentSuccessText'"
                  :id="'paymentSuccessText'"
                >
                </editor>
              </div>
            </div>
            <div>
              <h5 class="block text-base">Preview</h5>
              <div class="ds-wrapper">
                <div class="preview-card border-container preview pay-later-product-preview">
                  <div class="relative md:w-10/12 md:max-w-2lg md:mx-auto">
                    <div class="success-message text-center mt-16 mb-8 bg-success-200-transparent pt-10 px-4 pb-8 sm:pt-14 sm:px-6 sm:pb-10 rounded-2xl sm:rounded-3xl">
                      <div class="check-box bg-success-500 h-16 w-16 flex justify-center items-center rounded-full absolute">
                        <vs-icon type="flat" icon="done" class="text-shade-white" icon-size="large"></vs-icon>
                      </div>
                      <h2 class="mb-2 text-shade-black">{{ product.paymentSuccessHeading || "All done!" }}</h2>
                      <p class="mb-8 text-body-dark">Your payment reference is PR-0000-0000.</p>
                      <p class="mb-8 text-body-dark" v-html="product.paymentSuccessText"></p>
                      <div class="flex justify-center">
                        <div class="flex items-center gap-x-10 bg-neutral-50 rounded-lg py-1.5 px-3">
                          <p class="text-interaction-primary text-left">
                            <a href="#" class="no-underline" target="_blank">sample.rellopay.co/payment-request/1234-5678</a>
                          </p>
                          <vs-icon icon="file_copy" class="material-icons material-icons-outlined cursor-pointer" icon-size="medium"></vs-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-if="product.isManualAssessment || (manualAssessmentCriteria && manualAssessmentCriteria.length > 0)">
            <vs-row class="mt-20" >
              <vs-col vs-w="10">
                <h5>Payment submitted</h5>
              </vs-col>
              <vs-col vs-w="2" class="ds-wrapper">
                <a v-if="showPaymentSubmittedResetButton" @click="resetPaymentSubmittedText" class="no-underline text-interaction-primary flex gap-4">
                  <vs-icon icon-size="small" class="refresh-icon">refresh</vs-icon>
                  Reset to default
                </a>
              </vs-col>
            </vs-row>
            <vs-divider></vs-divider>
            <div class="sm:grid-cols-1 md:grid-cols-2 preview-section my-10 h-auto">
              <div>
                <div class="mb-6">
                  <label for="paymentSubmittedHeading" class="w-full text-xm font-normal">Section heading</label>
                  <vs-input size="large" v-model="product.paymentSubmittedHeading" v-validate="'required'" data-vv-as="section heading" name="paymentSubmittedHeading" id="paymentSubmittedHeading" />
                  <span class="text-danger text-sm block py-3" v-show="errors.has('paymentSubmittedHeading')">{{ errors.first("paymentSubmittedHeading") }}</span>
                </div>
                <div class="ds-wrapper">
                  <label for="paymentSubmittedEditor" class="w-full text-xm font-normal">Message</label>
                  <editor
                    :key="'paymentSubmittedEditor'"
                    ref="paymentSubmittedEditor"
                    v-model="product.paymentSubmittedText"
                    :counter="''"
                    class="quill-editor-max-width"
                    :editorOption="editorOption"
                    data-vv-as="paymentSubmittedText"
                    :validate="'required'"
                    :hasError="errors.has('paymentSubmittedText')"
                    :errorMessage="errors.first('paymentSubmittedText')"
                    :name="'paymentSubmittedText'"
                    :id="'paymentSubmittedText'"
                  >
                  </editor>
                </div>
              </div>
              <div>
                <h5 class="block text-base">Preview</h5>
                <div class="ds-wrapper">
                  <div class="preview-card border-container preview pay-later-product-preview">
                    <div class="relative md:w-10/12 md:max-w-2lg md:mx-auto">
                      <div class="inactive-link yellow-border text-center mt-16 mb-8 bg-warning-500-transparent pt-10 px-4 pb-8 sm:pt-14 sm:px-6 sm:pb-10 rounded-2xl sm:rounded-3xl">
                        <div class="check-box bg-warning-500 h-16 w-16 flex justify-center items-center rounded-full absolute">
                          <vs-icon type="flat" icon="error_outline" class="text-shade-white" icon-size="large"></vs-icon>
                        </div>
                        <h2 class="mb-2 text-shade-black">{{ product.paymentSubmittedHeading || "Nearly there …" }}</h2>
                        <p class="mb-8 text-body-dark">Your payment reference is PR-0000-0000.</p>
                        <p class="mb-8 text-body-dark" v-html="product.paymentSubmittedText"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <!--------------- legals section -------------------------->
    <vx-card class="accordin-card mt-5 legals" id="legals">
      <vs-collapse accordion>
        <vs-collapse-item open ref="legalsCollapse">
          <div slot="header">
            <h3 class="font-normal">Legals <span class="mid-blue text-xl align-top" >*</span></h3>
          </div>
          <p class="block mb-10">Please upload relevant legal documents for this product.</p>
          <div class="legals__item">
            <h5 class="font-medium mb-2 text-xl">Customer Terms & Conditions</h5>
            <hr class="line-hr mb-8" />
            <p class="block my-5">Create the T&Cs customers must accept on the payment page when making payments for this product.</p>
            <p class="block my-5" v-if="staticProductCustomerTerms">
              Static link:
              <a :href="staticProductCustomerTerms" target="_blank">{{ staticProductCustomerTerms }}</a>
            </p>
            <div class="flex flex-row" :class="{ 'justify-end': customerTermsAndConditions.length > 0 }">
              <vs-button v-round color="primary" icon="add" @click="createProductLegal('CUSTOMER')" v-if="customerTermsAndConditions.length === 0">
                Create document
              </vs-button>
              <vs-button v-round class="only-border-btn mb-4" size="medium" @click="createProductLegal('CUSTOMER', true)" v-else>
                <plus-icon size="1.5x" class="button-icon"></plus-icon> New version
              </vs-button>
            </div>

            <vs-table :data="customerTermsAndConditions" v-if="customerTermsAndConditions.length > 0">
              <template slot="thead">
                <vs-th class="legals-th">Document name</vs-th>
                <vs-th class="legals-th">Date created</vs-th>
                <vs-th class="legals-th">Version</vs-th>
                <vs-th></vs-th>
                <vs-th></vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].documentName">{{ data[indextr].documentName }}</vs-td>
                  <vs-td :data="data[indextr].createdAt">{{ formatScheduleDate(data[indextr].createdAt) }}</vs-td>
                  <vs-td :data="data[indextr].id">{{ data[indextr].version }}</vs-td>
                  <vs-td :data="data[indextr].id"><span class="active-badge" v-if="indextr === 0">Active</span></vs-td>
                  <vs-td :data="data[indextr].id" class="view-btn"><a class="underline" v-if="checkLegalId(tr._id)" @click="viewLegal(tr._id)">view</a></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>

          <div class="legals__item">
            <div class="flex justify-between">
              <h5 class="font-medium mb-2 text-xl">Merchant Terms & Conditions</h5>
              <vs-switch color="#0f67f4" v-model="product.isMerchantLegalActive" />
            </div>
            <hr class="line-hr mb-8" />
            <div v-if="product.isMerchantLegalActive">
              <p class="block my-5">Create the T&Cs merchants must accept when enabling this product on their account.</p>
              <div class="flex flex-row" :class="{ 'justify-end': merchantTermsAndConditions.length > 0 }">
                <vs-button v-round color="primary" icon="add" @click="createProductLegal('MERCHANT')" v-if="merchantTermsAndConditions.length === 0">Create document</vs-button>
                <vs-button class="only-border-btn mb-4" v-round size="medium" @click="createProductLegal('MERCHANT', true)" v-else>
                  <plus-icon size="1.5x" class="button-icon"></plus-icon> New version
                </vs-button>
              </div>

              <vs-table :data="merchantTermsAndConditions" v-if="merchantTermsAndConditions.length > 0" class="mb-5">
                <template slot="thead">
                  <vs-th class="w-2/12 legals-th">Document name</vs-th>
                  <vs-th class="w-2/12 legals-th">Date created</vs-th>
                  <vs-th class="legals-th">Version</vs-th>
                  <vs-th></vs-th>
                  <vs-th></vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td class="capitalize" :data="data[indextr].documentName">{{ data[indextr].documentName }}</vs-td>
                    <vs-td :data="data[indextr].createdAt">{{ formatScheduleDate(data[indextr].createdAt) }}</vs-td>
                    <vs-td :data="data[indextr].id">{{ data[indextr].version }}</vs-td>
                    <vs-td :data="data[indextr].id"><span class="active-badge" v-if="indextr === 0">Active</span></vs-td>
                    <vs-td :data="data[indextr].id" class="view-btn"><a class="underline" v-if="checkLegalId(tr._id)" @click="viewLegal(tr._id)">view</a></vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <span class="text-danger text-sm block py-3" v-show="errors.has('legals')">{{ errors.first("legals") }}</span>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <!--------------- Integration guide section -------------------------->
    <vx-card class="accordin-card mt-5" id="integrationBox">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header"><h3 class="font-normal">Integration guide<span class="mid-blue text-xl align-top">*</span></h3></div>
          <div class="flex flex-row" v-if="!checkIntegrationGuide.length">
            <vs-button v-round color="primary" icon="add" @click="createIntegrationGuide()">New article</vs-button>
          </div>
          <vs-table v-else :data="checkIntegrationGuide" class="mt-2">
            <template slot="thead">
              <vs-th width="400px">Article name</vs-th>
              <vs-th>Last updated</vs-th>
              <vs-th></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr>
                <vs-td>{{ integrationGuide.articleName }}</vs-td>
                <vs-td>{{ formatIntegrationDate(integrationGuide.createdAt) }}</vs-td>
                <vs-td class="text-right"><u><a @click="handleIntegrationGuideEdit()">Edit</a></u></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <!-- Configurable communications -->
    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item open>
          <div slot="header">
            <h3 class="font-normal">Configurable communications</h3>
          </div>
          <div class="grid grid-cols-1">
            <div class="flex mb-4">
              <vs-switch v-model="product.creditDefaultCommunication.d1" size="medium" />
              <p class="ml-4">End of plan demand for payment</p>
            </div>
            <div class="flex mb-4">
              <vs-switch v-model="product.creditDefaultCommunication.d2" size="medium" />
              <p class="ml-4">Weekly reminder of overdue balance</p>
            </div>
            <div>
              <div class="flex mb-4">
                <vs-switch v-model="product.creditDefaultCommunication.automatedCreditDefaultWarning.enableComms" size="medium" />
                <p class="ml-4">Automated credit default warning</p>
              </div>
              <div v-if="product.creditDefaultCommunication.automatedCreditDefaultWarning.enableComms">
                <label for="daysAfterPlanEnd" class="w-full text-xm font-normal">Days after plan end <span class="required-text">*</span></label>
                <div class="flex gap-3 items-center mb-4">
                  <vs-input size="large" v-model="product.creditDefaultCommunication.automatedCreditDefaultWarning.daysAfterPlanEnd" data-vv-validate-on="blur" data-vv-as="days after plan end" v-validate="'required|min_value:1'" name="daysAfterPlanEnd" id="daysAfterPlanEnd" />
                  <span>days</span>
                </div>
                <span class="text-danger text-sm block pb-3" v-if="errors.has('daysAfterPlanEnd')">{{ errors.first("daysAfterPlanEnd") }}</span>
              </div>
            </div>
            <div>
              <div class="flex mb-4">
                <vs-switch v-model="product.creditDefaultCommunication.automatedSecurityInterestWarning.enableComms" size="medium" />
                <p class="ml-4">Automated security interest warning</p>
              </div>
              <div v-if="product.creditDefaultCommunication.automatedSecurityInterestWarning.enableComms">
                <label for="securityInterestWarningDaysAfterPlanEnd" class="w-full text-xm font-normal">Days after plan end <span class="required-text">*</span></label>
                <div class="flex gap-3 items-center mb-4">
                  <vs-input size="large" v-model="product.creditDefaultCommunication.automatedSecurityInterestWarning.daysAfterPlanEnd" data-vv-validate-on="blur" data-vv-as="days after plan end" v-validate="'required|min_value:1'" name="securityInterestWarningDaysAfterPlanEnd" id="securityInterestWarningDaysAfterPlanEnd" />
                  <span>days</span>
                </div>
                <span class="text-danger text-sm block pb-3" v-if="errors.has('securityInterestWarningDaysAfterPlanEnd')">{{ errors.first("securityInterestWarningDaysAfterPlanEnd") }}</span>
              </div>
            </div>
            <div>
              <div class="flex mb-4" v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(this.$route.query.productType)">
                <vs-switch v-model="product.upcomingPaymentReminder.enableComms" size="medium" />
                <p class="ml-4">Configurable reminder</p>
              </div>
              <div v-if="product.upcomingPaymentReminder.enableComms">
                <div class="flex gap-3 items-center mb-4">
                  <vs-input size="large" v-model="product.upcomingPaymentReminder.paymentReminderDays" data-vv-as="payment reminder days" data-vv-validate-on="blur" v-validate="'required|min_value:1'" name="paymentReminderDays" id="paymentReminderDays" />
                  <span>days</span>
                </div>
                <span class="text-danger text-sm block pb-3" v-if="errors.has('paymentReminderDays')">{{ errors.first("paymentReminderDays") }}</span>
              </div>
            </div>
            <div>
              <div class="flex mb-4">
                <vs-switch v-model="product.creditDefaultCommunication.configurableCheckIn.enableComms" size="medium" />
                <p class="ml-4">Configurable check-in</p>
              </div>
              <div v-if="product.creditDefaultCommunication.configurableCheckIn.enableComms">
                <label for="checkInDays" class="w-full text-xm font-normal">Send every &lt;n&gt; days <span class="required-text">*</span></label>
                <div class="flex gap-3 items-center mb-4">
                  <vs-input size="large" v-model="product.creditDefaultCommunication.configurableCheckIn.checkInDays" data-vv-validate-on="blur" data-vv-as="check in days" v-validate="'required|min_value:1'" name="checkInDays" id="checkInDays" />
                  <span>days</span>
                </div>
                <span class="text-danger text-sm block pb-3" v-if="errors.has('checkInDays')">{{ errors.first("checkInDays") }}</span>
              </div>
            </div>
            <div v-if="product.productConfigType == 'THREE_PARTY'">
              <div class="flex mb-4">
                <vs-switch v-model="product.threePartyMerchantReminder.enableComms" size="medium" />
                <p class="ml-4">Send 3PP reminders/confirmations to merchant</p>
              </div>
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
    <!-- Default communications Ends -->

    <!--------------- Communication section -------------------------->
    <vx-card class="accordin-card mt-5 legals" id="Communication" v-if="$route.query.id">
      <vs-collapse accordion>
        <vs-collapse-item open ref="communicationCollapse">
          <div slot="header"><h3 class="font-normal">Communications</h3></div>
          <h5 class="font-medium text-xl" id="email-template">Email</h5>
          <vs-table :data="emailTemplates" class="mt-2">
            <template slot="thead">
              <vs-th width="400px">Template type</vs-th>
              <vs-th>ID</vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.templateCategory">{{ tr.templateCategory }}</vs-td>
                <vs-td :data="tr.templateId">{{ tr.templateId }}</vs-td>
                <vs-td :data="tr" class="text-right"><u><a @click="handleTemplateEdit(tr._id, 'Email')">Edit</a></u></vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <div class="flex mt-5">
            <h5 class="font-medium text-xl">Sms</h5>
            <vs-switch v-model="product.smsEnabled" name="smsEnabled" class="ml-5" />
          </div>

          <vs-table :data="smsTemplates" class="mt-2">
            <template slot="thead">
              <vs-th width="400px">Template type</vs-th>
              <vs-th>ID</vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.templateCategory">{{ tr.templateCategory }}</vs-td>
                <vs-td :data="tr.templateId">{{ tr.templateId }}</vs-td>
                <vs-td :data="tr" class="text-right"><u><a @click="handleTemplateEdit(tr._id, 'SMS')">Edit</a></u></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <pop-up :errorsList="errors" form-name="PayLaterForm"></pop-up>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

    <vs-row class="mt-5 flex justify-end items-center">
      <vs-button v-if="productEdit" size="large" v-round class="only-border-btn mr-8" type="border" @click="popupDelActive = 'true'">Delete Product</vs-button>
      <vs-button class="text-sm" size="large" v-round :disabled="!validateForm" @click="prepareSubmit" color="primary">Save</vs-button>
      <a class="text-base flex items-center underline mid-blue ml-8" @click="$router.go(-1)">Cancel</a>
    </vs-row>

    <delete-popup :popup-active="popupDelActive" :deleteWhat="deleteWhat" :buttonText="buttonText" @handleDeleteClick="delActions"></delete-popup>
  </div>
</template>

<script>
import RadioBox from "@/views/components/radiobox";
import { Money } from "v-money";
import moment from "moment";
import slugify from "slugify";
import _ from "lodash";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import CustomFields from "@/views/components/CustomField";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Textarea from "@/views/forms/form-elements/textarea/Textarea";
import { RotateCcwIcon } from "vue-feather-icons";
import { PlusIcon, CalendarIcon, Trash2Icon } from "vue-feather-icons";
import { addDays, addMonths, format } from "date-fns";
import { Validator } from "vee-validate";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
import CardPayIcon from "../../../components/design-sys-components/icons/CardPayIcon";
import DirectDebitIcon from "../../../components/design-sys-components/icons/DirectDebitIcon";
import PreviewCard from "./PreviewCard.vue";
import PaymentSummaryPreview from "./PaymentSummaryPreview.vue";
import DeletePopup from "@/views/components/DeletePopup";

import Editor from "../../../QuillEditorCustom.vue";
import SegmentedButtons from "../../../components/design-sys-components/components/SegmentedButtons";
import { VStepper } from "vue-stepper-component";
import { TheMask } from "vue-the-mask";

const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const questionIcon = require("@/assets/images/toolbox.svg");
const defaultSectionHeading = "Payment method";
const disbursementHeading = "Disbursement account";
const disbursementDescription = "We will transfer funds into the below account once your plan is approved.";

const dictionary = {
  en: {
    attributes: {
      contactDetailMessage: "contact message",
      marketingBannerText: "marketing banner",
      requiredInformationText: "required information message",
      identityText: "identity message",
      paymentMethodDescription: "message",
      paymentSuccessText : "message",
      paymentSubmittedText: "message"
    },
  },
};

Validator.localize(dictionary);

export default {
  name: "PayLaterProduct",
  components: {
    CalendarIcon,
    CardPayIcon,
    CustomFields,
    DeletePopup,
    DirectDebitIcon,
    Editor,
    LeavePopup,
    Money,
    PaymentSummaryPreview,
    "popper": Popper,
    PopUp,
    PreviewCard,
    PlusIcon,
    RadioBox,
    RotateCcwIcon,
    SegmentedButtons,
    Textarea,
    TheMask,
    Trash2Icon,
    VStepper,
  },
  data() {
    return {
      staticProductCustomerTerms: null,
      steps: 4,
      contactStep: 1,
      requireInformationStep: 2,
      identityStep: 3,
      paymentMethodStep: 4,
      bankAccountStep: 4,
      editorOption: {
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "link",
            ],
          ],
        },
        placeholder: "Required"
      },
      step: 1,
      showField: true,
      tillConnectors: [],
      id: "",
      toggleFlex: false,
      defaultExtraConditionsCriteria: [
        {
          text: "Max % of product field",
          value: "MAX_PERCENT_OF_PRODUCT_FIELD",
        },
        {
          text: "Product field as due date",
          value: "PRODUCT_FIELD_AS_DUE_DATE",
        }
      ],
      extraConditionsList: [],
      customFieldBind: true,
      isSaved: false,
      parsedLegals: [],
      popupActive: false,
      customFieldList: [],
      nextObj: "", // determines the next route,
      money2: {
        precision: 0,
        prefix: "$ ",
      },
      money2Percentage: {
        precision: 0,
        suffix: "%",
      },
      money3: {
        precision: 0
      },
      frequencyInterval: {
        daily: 1,
        weekly: 7,
        fortnightly: 14,
        monthly: 30,
      },
      money: {
        precision: 2,
        suffix: "%",
      },
      numeric: {
        precision: 0,
      },
      active_el: true,
      product: {
        _id: "",
        productName: "",
        isMerchantLegalActive: true,
        productDescription: "",
        feeLabel: "Fee",
        feeModel: "MSF_INCLUDED",
        productType: "",
        msf: "",
        legals: [],
        integrationGuide: {},
        customFields: [],
        multiplePayments: false,
        initialPeriod: 0,
        customerType: "CONSUMER",
        maxDuration: 0,
        productText: "",
        noOfPayments: 0,
        paymentFrequency: "DAILY",
        flipConnector: "",
        settlementAccount: "test",
        amountLabel: "Amount",
        totalAmountLabel: "Total",
        disbursementLabel: "Disbursement",
        purchaseAmountLabel: "Purchase amount",
        message: "Our share of proceeds will be deducted on",
        marketingBanner: false,
        marketingBannerText: "",
        cardTitle: "Your payment",
        dateLabel: "Payment Date",
        feeText: "Your payments include a {{Fee %}} service fee",
        extraConditions: [],
        gracePeriod: 0,
        isManualAssessment: false,
        manualAssessmentEmail: "",
        criteriaAssessmentEmail: "",
        productConfigType: "THREE_PARTY",
        showTransactionBalance: false,
        introducerUseOnly: false,
        autoDebit: true,
        paymentMethods: {
          paymentPage: ["CARD"],
          customerPortal: ["CARD"],
        },
        dishonourFee: 0,
        isDishonourFeeActive: false,
        creditDefaultCommunication: {
          d1: true,
          d2: true,
          automatedCreditDefaultWarning: {
            enableComms: true,
            daysAfterPlanEnd: 14,
          },
          configurableCheckIn: {
            enableComms: false,
            checkInDays: 28,
          },
          automatedSecurityInterestWarning: {
            enableComms: true,
            daysAfterPlanEnd: 14,
          }
        },
        contactDetailMessage: "",
        requiredInformation: false,
        requiredInformationText: "",
        identityText: "",
        summaryAmountLabel: "",
        summaryFeeLabel: "",
        summaryHeader: "",
        summaryTotalLabel: "",
        summaryMessage: "",
        summaryButtonText: "",
        paymentSuccessHeading: "",
        paymentSuccessText: "",
        isPayNow: false,
        paymentSubmittedHeading: "",
        paymentSubmittedText: "",
        upcomingPaymentReminder: {
          enableComms: false,
          paymentReminderDays: 7
        },
        messageOne: "",
        messageTwo: "",
        contactEmail: "",
        contactNumber: "",
        assignmentText: "",
        repaymentBy: "",
        extension: {
          isEnabledExtension: false,
          frequency: null,
          fee: 0,
          maxOccurrences: null,
          startDaysAfter: null
        },
        configurableFeeText: "",
        summaryConfigurableFeeText: "",
        explainer: {
          isEnabled: false,
          header: "",
          content: "",
          buttonText: "I understand, proceed"
        },
        merchantTopUp: {
          isEnabled: false,
          description: "",
          feeAmount: 0
        },
        threePartyMerchantReminder: {
          enableComms: false
        },
        smsEnabled: true,
        allowDisbursementDateOverride: false
      },
      paymentPage: {
        paymentMethodDescription: "",
        showPaymentMethodDescription: false,
        paymentSectionHeading: defaultSectionHeading,
      },
      settlementAccounts: [],
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      questionIcon: questionIcon,
      frequency: [
        { text: "Daily", value: "DAILY" },
        { text: "Weekly", value: "WEEKLY" },
        { text: "Fortnightly", value: "FORTNIGHTLY" },
        { text: "Monthly", value: "MONTHLY" },
      ],
      emailTemplates: [],
      smsTemplates: [],
      isBNPO: false,
      criteriaItem: {
        criteria: "",
        productField: "",
        dataList: []
      },
      manualAssessmentCriteria: [],
      defaultCriteriaData: [
        {
          title: "Request amount is greater than $",
          value: "AMOUNT_GREATER_THAN"
        },
        {
          title: "Request amount is greater than %",
          value: "AMOUNT_GREATER_THAN_PERCENTAGE"
        },
        {
          title: "Dropdown field has the value of",
          value: "DROPDOWN_HAS_VALUE"
        },
        {
          title: "Customer has an existing active PR",
          value: "CUSTOMER_HAS_ACTIVE_PR"
        },
        {
          title: "Payment request created from a HPP",
          value: "HPP_PAYMENT_REQUEST"
        },
        {
          title: "Product field not populated",
          value: "PRODUCT_FIELD_NOT_POPULATED"
        }
      ],
      productEdit: false,
      planReady: false,
      integrationGuide: {},
      disbursement: {
        description: disbursementDescription,
        showDescription: true,
        sectionHeading: disbursementHeading,
        disburseTo: {},
        editDisbursement: {
          customer: false,
          merchant: false,
          introducer: false,
        }
      },
      segmentBtn: {},
      segmentButton: [
        {
          label: "Merchant",
          active: true,
          value: "merchant"
        },
        {
          label: "External",
          active: false,
          value: "external"
        }
      ],
      defaultContactMessage: "We require your contact details to send you communication regarding your agreement. Don't worry, we won't spam you.",
      defaultIdentityText: "We need to verify your identity before proceeding. We won't use your data for any other purpose, or share or store it beyond regulatory requirements",
      defaultPaymentSuccessText: "Manage your details, make payments, or simply track the progress of your payment plan at your unique link below.",
      defaultPaymentSubmittedText: "We just need to review a few details before approving your payment, we'll SMS/email you within the next 1-2 hours",
      deleteWhat: "product",
      buttonText: "Delete",
      popupDelActive: false,
      repaymentByList: [],
    };
  },
  computed: {
    ...mapGetters("paymentPlans", ["getCurrentExtraConditions", "getCurrentManualAssessmentCriteria", "getCurrentPaymentPlan", "getDisbursement", "getPaymentMethod"]),

    disablePaynow() {
      let disable = true;

      if (this.product.productConfigType == "THREE_PARTY") {
        disable = this.segmentButton.find(item => item.active && item.value == "external");
      }

      return disable ? "disabled" : "";
    },

    schedule() {
      return this.getSchedule(this.product.noOfPayments, this.product.paymentFrequency);
    },

    checkIntegrationGuide () {
      return Object.keys(this.integrationGuide);
    },

    perInstallmentAmount() {
      return this.truncate(1000 / this.product.noOfPayments);
    },

    payable() {
      let frequency = this.product.paymentFrequency;
      let payable = "";

      if (frequency === "WEEKLY") {
        payable = "1 week";
      } else if (frequency === "FORTNIGHTLY") {
        payable = "2 weeks";
      } else if (frequency === "MONTHLY") {
        payable = "1 month";
      } else if (frequency === "DAILY") {
        payable = "1 day";
      }
      return payable;
    },

    customerTermsAndConditions() {
      const terms = this.parsedLegals.filter((legal) => legal.user === "CUSTOMER");
      return _.sortBy(terms, ["createdAt"]).reverse();
    },

    merchantTermsAndConditions() {
      const terms = this.parsedLegals.filter((legal) => legal.user === "MERCHANT");
      return _.sortBy(terms, ["createdAt"]).reverse();
    },

    maxValidation() {
      if (this.product.paymentFrequency === "DAILY") {
        return "required|min_value:2|max_value:62";
      } else if (this.product.paymentFrequency === "WEEKLY") {
        return "required|min_value:2|max_value:8";
      } else if (this.product.paymentFrequency === "FORTNIGHTLY") {
        return "required|min_value:2|max_value:5";
      } else {
        return "required|min_value:2|max_value:3";
      }
    },

    getDropdownFields: function () {
      return function (field) {
        const selectedField = this.manualAssessmentCriteria.map(item => (item.criteria == "DROPDOWN_HAS_VALUE" && item.productField != field) ? item.productField : null).filter(item => item);
        let dropdownFields = this.customFieldList.filter(item => (item.type === "dropdown") && !selectedField.includes(item.label));

        dropdownFields = dropdownFields.map(item => {
          return {
            dropdownSelectFields: item.dropdownSelectFields,
            label: item.label
          }
        })

        return dropdownFields;
      };
    },

    getFieldNotPopulatedList: function () {
      return function (field) {
        const selectedField = this.manualAssessmentCriteria.map(item => (item.criteria == "PRODUCT_FIELD_NOT_POPULATED" && item.productField != field) ? item.productField : null).filter(item => item);
        // IF PRODUCT IS TWO PARTY THEN CHECK REQUIRED TO SEND ELSE CHECK REQUIRED TO SUBMIT
        let dropdownFields = this.customFieldList.filter(item => (this.product.productConfigType == "TWO_PARTY" ? !item.reqToSendRequest : !item.requiredToSubmit) && !selectedField.includes(item.label));

        dropdownFields = dropdownFields.map(item => {
          return {
            dropdownSelectFields: item.dropdownSelectFields,
            label: item.label
          }
        })

        return dropdownFields;
      };
    },

    productFieldsForExtraConditions: function () {
      return function (field) {
        let dropdownFields = this.customFieldList.filter(item => {
          if (field == "MAX_PERCENT_OF_PRODUCT_FIELD") {
            return item.type == "currency";
          } else if (field == "PRODUCT_FIELD_AS_DUE_DATE") {
            return item.type == "date" && item.reqToSendRequest == true;
          } else if (field == "AMOUNT_GREATER_THAN_PERCENTAGE") { // this is for criteria manual assessment
            return ["currency", "number"].includes(item.type);
          }
        });

        dropdownFields = dropdownFields.map(item => {
          return {
            text: item.label,
            value: item.customFieldId
          }
        })

        return dropdownFields;
      };
    },

    defaultCriteria: function () {
      return function (option) {
        let criteria = [...this.defaultCriteriaData];

        if (this.product.productConfigType == "TWO_PARTY") {
          criteria = criteria.filter(item => item.value != "CUSTOMER_HAS_ACTIVE_PR");
        }

        const amountGreaterCriteria = this.manualAssessmentCriteria.find(item => item.criteria == "AMOUNT_GREATER_THAN");
        const customerHasActivePrCriteria = this.manualAssessmentCriteria.find(item => item.criteria == "CUSTOMER_HAS_ACTIVE_PR");
        const hppPrCriteria = this.manualAssessmentCriteria.find(item => item.criteria == "HPP_PAYMENT_REQUEST");
        const requestAmountPercentageCriteria = this.manualAssessmentCriteria.find(item => item.criteria == "AMOUNT_GREATER_THAN_PERCENTAGE");

        if (option) {
          let list = [...criteria];
          switch (option) {
            case "AMOUNT_GREATER_THAN": {
              list = criteria.filter(item => {
                if ((customerHasActivePrCriteria && item.value == "CUSTOMER_HAS_ACTIVE_PR") || (hppPrCriteria && item.value == "HPP_PAYMENT_REQUEST") || (requestAmountPercentageCriteria && item.value == "AMOUNT_GREATER_THAN_PERCENTAGE")) {
                  return null;
                }

                return item;
              }).filter(item => item);
              break;
            }

            case "CUSTOMER_HAS_ACTIVE_PR": {
              list = criteria.filter(item => {
                if ((amountGreaterCriteria && item.value == "AMOUNT_GREATER_THAN") || (hppPrCriteria && item.value == "HPP_PAYMENT_REQUEST") || (requestAmountPercentageCriteria && item.value == "AMOUNT_GREATER_THAN_PERCENTAGE")) {
                  return null;
                }

                return item;
              }).filter(item => item);
              break;
            }

            case "HPP_PAYMENT_REQUEST": {
              list = criteria.filter(item => {
                if ((amountGreaterCriteria && item.value == "AMOUNT_GREATER_THAN") || (customerHasActivePrCriteria && item.value == "CUSTOMER_HAS_ACTIVE_PR") || (requestAmountPercentageCriteria && item.value == "AMOUNT_GREATER_THAN_PERCENTAGE")) {
                  return null;
                }

                return item;
              }).filter(item => item);
              break;
            }

            case "AMOUNT_GREATER_THAN_PERCENTAGE": {
              list = criteria.filter(item => {
                if ((amountGreaterCriteria && item.value == "AMOUNT_GREATER_THAN") || (customerHasActivePrCriteria && item.value == "CUSTOMER_HAS_ACTIVE_PR") || (hppPrCriteria && item.value == "HPP_PAYMENT_REQUEST")) {
                  return null;
                }

                return item;
              }).filter(item => item);
              break;
            }

            case "PRODUCT_FIELD_NOT_POPULATED":
            case "DROPDOWN_HAS_VALUE": {
              list = criteria.filter(item => {
                if ((amountGreaterCriteria && item.value == "AMOUNT_GREATER_THAN") || (customerHasActivePrCriteria && item.value == "CUSTOMER_HAS_ACTIVE_PR") || (hppPrCriteria && item.value == "HPP_PAYMENT_REQUEST") || (requestAmountPercentageCriteria && item.value == "AMOUNT_GREATER_THAN_PERCENTAGE")) {
                  return null;
                }

                return item;
              }).filter(item => item);
              break;
            }
          }

          return list;
        }

        return criteria.filter(item => {
          if (amountGreaterCriteria && item.value == "AMOUNT_GREATER_THAN") {
            return null;
          } else if (customerHasActivePrCriteria && item.value == "CUSTOMER_HAS_ACTIVE_PR") {
            return null;
          } else if (hppPrCriteria && item.value == "HPP_PAYMENT_REQUEST") {
            return null;
          } else if (requestAmountPercentageCriteria && item.value == "AMOUNT_GREATER_THAN_PERCENTAGE") {
            return null;
          }
          return item;
        }).filter(item => item);
      };
    },

    extraConditionsCriteria: function () {
      return function (option) {
        const selectedConditions = this.extraConditionsList.map(item => item.criteria != option && item.criteria).filter(item => item);
        let extraConditionsCriteria = this.defaultExtraConditionsCriteria.filter(item => !selectedConditions.includes(item.value));

        if (this.product.productType == "B2C_BNPL") {
          extraConditionsCriteria = extraConditionsCriteria.filter(item => item.value != "PRODUCT_FIELD_AS_DUE_DATE");
        }

        return extraConditionsCriteria;
      };
    },

    showAddExtraConditionOption() {
      return this.extraConditionsList.length < (this.product.productType == "B2C_BNPL" ? this.defaultExtraConditionsCriteria.length - 1 : this.defaultExtraConditionsCriteria.length);
    },

    showProductSummaryResetButton() {
      const {
        amountLabel,
        buttonText,
        feeLabel,
        headerLabel,
        message,
        totalAmountLabel,
      } = this.defaultPaymentCardDetail;

      let display = false;

      display = this.product.summaryTotalLabel != totalAmountLabel || this.product.summaryFeeLabel != feeLabel || this.product.summaryHeader != headerLabel || this.product.summaryAmountLabel != amountLabel || this.product.summaryButtonText !== buttonText;

      if (this.$route.query.productType !== "B2C_BNPL") {
        display = display || this.product.summaryMessage != message;
      }

      return display;
    },

    showSummaryCardResetButton() {
      return this.paymentPage.paymentSectionHeading != this.defaultSectionHeading || this.paymentPage.showPaymentMethodDescription || this.paymentPage.paymentMethodDescription;
    },

    showPaymentSuccessReset() {
      return this.product.paymentSuccessText.replace("<p>", "").replace("</p>", "") !== this.defaultPaymentSuccessText;
    },

    showPaymentSubmittedResetButton() {
      return this.product.paymentSubmittedText.replace("<p>", "").replace("</p>", "") !== this.defaultPaymentSubmittedText;
    },

    showDisbursementResetButton() {
      return this.disbursement.sectionHeading != disbursementHeading || !this.disbursement.showDescription || !this.disbursement.description;
    },

    showProductCardResetButton() {
      let display = false;

      const {
        amountLabel,
        cardTitle,
        dateLabel,
        disbursementLabel,
        feeLabel,
        message,
        purchaseAmountLabel,
        totalAmountLabel,
      } = this.defaultPaymentCardDetail;

      if (this.$route.query.productType == "B2C_BNPL") {
        display =  this.product.totalAmountLabel != totalAmountLabel || this.product.cardTitle != cardTitle || this.product.amountLabel != amountLabel || this.product.dateLabel != dateLabel;
      } else {
        display = this.product.totalAmountLabel != totalAmountLabel || this.product.cardTitle != cardTitle || this.product.feeLabel != feeLabel || this.product.disbursementLabel != disbursementLabel || this.product.purchaseAmountLabel != purchaseAmountLabel || this.product.message != message;
      }

      return (display || this.product.marketingBanner) ;
    },

    showContactDetailMessage() {
      return (this.product.contactDetailMessage.replace("<p>", "").replace("</p>", "")) !== this.defaultContactMessage;
    },

    showResetIdentity() {
      return (this.product.identityText.replace("<p>", "").replace("</p>", "")) !== this.defaultIdentityText;
    },

    showDishonourBox() {
      const directDebit = this.product.paymentMethods.customerPortal.includes("DIRECT_DEBIT");
      const autoDebit = this.product.autoDebit;

      return (autoDebit && directDebit);
    },

    showDishonourFee() {
      return (this.showDishonourBox && this.product.isDishonourFeeActive);
    },

    calculateFees() {
      let calculatedFee = this.product.msf * 0.01 * 1000;

      if (this.product.minimumMsfFeeAmount > calculatedFee) {
        calculatedFee = this.product.minimumMsfFeeAmount;
      }

      return calculatedFee;
    },

    defaultPaymentCardDetail() {
      return {
        amountLabel: "Amount",
        feeLabel: "Fee",
        headerLabel: "Your agreement",
        totalAmountLabel: "Total",
        disbursementLabel: "Disbursement",
        purchaseAmountLabel: "Purchase amount",
        message: "Our share of proceeds will be deducted on",
        cardTitle: this.$route.query.productType == "B2C_BNPL" ? "Your payment" : "Your agreement",
        dateLabel: "Payment Date",
        marketingBanner: false,
        marketingBannerText: "",
        buttonText: "Accept plan",
      }
    },

    checkPhoneNumber() {
      let mask = "#### ### ###";

      if (this.product.contactNumber && this.product.contactNumber.length > 0) {
        let numLength = this.product.contactNumber.length;
        let value1 = "";
        let value2 = "";

        if (numLength >= 2) {
          value1 = this.product.contactNumber.substring(0, 2);

          if (value1 === "13") {
            value2 = this.product.contactNumber.substring(0, 4);
          }
        }
        if (value1 === "02" || value1 === "03" || value1 === "07" || value1 === "08") {
          mask = "## #### ####";
        } else {
          mask = "#### ### ###";
        }

        if (value1 === "13") {
          if (parseInt(value2) >= 1300 || value2 === "130" || value2 === "13 0") {
            mask = "#### ### ###";
          } else {
            mask = "## ## ##";
          }
        }
      }

      return mask;
    },

    validEditDisbursement() {
      return this.disbursement.editDisbursement && (this.disbursement.editDisbursement.customer|| this.disbursement.editDisbursement.merchant || this.disbursement.editDisbursement.introducer);
    },

    displayMerchantTopUp() {
      return (this.product.productConfigType == "TWO_PARTY") || (this.product.productConfigType == "THREE_PARTY" && this.product.showTransactionBalance);
    },
  },

  methods: {
    ...mapActions("flipBankAccounts", ["fetchAllFlipBankAccounts"]),
    ...mapActions("connector", ["getAllAdminConnectors"]),
    ...mapActions("paymentPlans", ["createPlan", "deletePlan", "updatePlan"]),
    ...mapMutations("paymentPlans", ["SET_CURRENT_PLAN", "SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA", "UNSET_CURRENT_MANUAL_ASSESSMENT_CRITERIA", "SET_EXTRA_CONDITIONS", "UNSET_EXTRA_CONDITIONS", "SET_PAYMENT_METHOD_INFO", "UNSET_PAYMENT_METHOD_INFO", "SET_DISBURSEMENT_INFO", "UNSET_DISBURSEMENT_INFO"]),
    ...mapActions("setting", ["fetchSetting"]),
    ...mapActions("admin", ["checkLogin"]),

    auto_grow(elem) {
      let element = document.getElementById(elem);

      if (element) {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
      }
    },
    async checkAdminLogin() {
      await this.checkLogin().then(res => {
        if (!res.data.data.permissions["products"]) {
          this.$router.push({ name: "dashboard" });
        }
      });
    },

    handleSegmentedButtons(data) {
      this.disbursement.disburseTo = data;

      if (this.disbursement.disburseTo.value == "merchant") {
        this.disbursement.editDisbursement = {
          customer: false,
          introducer: false,
          merchant: false,
        };
      } else {
        this.product.isPayNow = false;
      }

      if (this.errors.has("editDisbursement")) {
        this.errors.remove("editDisbursement");
      }
    },

    getSchedule(numberOfPayments, paymentFrequency) {
      const schedules = [];
      const now = new Date(); // current date
      for (let i = 0; i < numberOfPayments; i++) {
        let paymentSchedule;

        switch (paymentFrequency.toLowerCase()) {
          case "weekly":
            paymentSchedule = addDays(now, i * this.frequencyInterval.weekly);
            break;
          case "fortnightly":
            paymentSchedule = addDays(now, i * this.frequencyInterval.fortnightly);
            break;
          case "monthly":
            paymentSchedule = addMonths(now, i);
            break;
          default:
            paymentSchedule = addDays(now, i * this.frequencyInterval.daily);
        }

        schedules.push(paymentSchedule.toISOString());
      }
      return schedules;
    },

    formatScheduleDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    pushToCustomFields(customFieldList) {
      this.$refs.productFieldCollapse.maxHeight = "none !important";
      this.customFieldList = customFieldList;
      this.productFieldsForExtraConditions = this.getProductFieldsForExtraConditions();

      if (this.manualAssessmentCriteria && this.manualAssessmentCriteria.length) {
        this.manualAssessmentCriteria.forEach((item, index)=> {
          let checkProductFieldExists = this.customFieldList.find(value => value.label == item.productField);

          if (!checkProductFieldExists) {
            this.manualAssessmentCriteria[index].productField = "";
          }

          // IF PRODUCT IS TWO PARTY THEN CHECK REQUIRED TO SEND ELSE CHECK REQUIRED TO SUBMIT
          // IF FIELD IS REQUIRED THEN REMOVE ASSIGNED FIELD TO MANUAL ASSESSMENT
          if ((item.criteria == "PRODUCT_FIELD_NOT_POPULATED") && (item.productField == checkProductFieldExists.label) && (this.product.productConfigType == "TWO_PARTY" ? checkProductFieldExists.reqToSendRequest : checkProductFieldExists.requiredToSubmit)) {
            this.manualAssessmentCriteria[index].productField = "";
          }
        });
      }

      if (this.extraConditionsList && this.extraConditionsList.length) {
        this.extraConditionsList.forEach((item, index)=> {
          let checkProductFieldExists = this.customFieldList.find(value => value.customFieldId == item.productField);

          if (!checkProductFieldExists) {
            this.extraConditionsList[index].productField = "";
          }
        });
      }
    },

    checkLegalId(id) {
      return (id.includes("LG")) ? false : true;
    },

    viewLegal(id) {
      this.SET_CURRENT_PLAN(this.product);
      this.SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(this.manualAssessmentCriteria);
      this.SET_EXTRA_CONDITIONS(this.extraConditionsList);
      this.SET_PAYMENT_METHOD_INFO(this.paymentPage);
      this.SET_DISBURSEMENT_INFO(this.disbursement);

      const routeData = this.$router.resolve({
        name: "pay-later-legal-view",
        query: { type: "PRODUCT-LEGAL", fullDocument: false },
        params: { id },
      });

      window.open(routeData.href, '_blank');
    },

    createProductLegal(type, newVersion = false) {
      this.product.customFields = this.customFieldList;
      this.SET_CURRENT_PLAN(this.product);
      this.SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(this.manualAssessmentCriteria);
      this.SET_EXTRA_CONDITIONS(this.extraConditionsList);
      this.SET_PAYMENT_METHOD_INFO(this.paymentPage);
      this.SET_DISBURSEMENT_INFO(this.disbursement);

      let query = {};
      query.productType = this.$route.query.productType;
      query.type = type;
      query.newVersion = newVersion;

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      this.$router.push({ name: "pay-later-legals", query: query });
    },

    createIntegrationGuide() {
      this.product.customFields = this.customFieldList;
      this.SET_CURRENT_PLAN(this.product);
      this.SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(this.manualAssessmentCriteria);
      this.SET_EXTRA_CONDITIONS(this.extraConditionsList);
      this.SET_PAYMENT_METHOD_INFO(this.paymentPage);
      this.SET_DISBURSEMENT_INFO(this.disbursement);

      let query = {};
      query.productType = this.$route.query.productType;

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }

      this.$router.push({ name: "integration-guide", query: query});
    },

    getMaturityDate() {
      return this.formatScheduleDate(format(new Date(addDays(new Date(), this.product.initialPeriod)),"yyyy-MM-dd"));
    },

    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },

    async getTillConnectors() {
      await this.getAllAdminConnectors().then((result) => {
        this.tillConnectors = result.data.data;
      });
    },

    async getAllSettlementAccounts() {
      await this.fetchAllFlipBankAccounts().then((result) => {
        this.settlementAccounts = result.data.data.docs.filter((el) => el.tillConnectorDetails.length > 0);
      });
    },

    getPaymentPlanById(id) {
      const {
        amountLabel,
        buttonText,
        feeLabel,
        headerLabel,
        message,
        totalAmountLabel,
      } = this.defaultPaymentCardDetail;

      let self = this;
      this.$vs.loading();
      this.$store.dispatch("paymentPlans/getAPlan", id).then((result) => {
        const defaultExtension = this.product.extension;
        const defaultExplainer = this.product.explainer;

        this.product = _.pick({
          ...result.data.data,
          contactDetailMessage: result.data.data.contactDetailMessage || this.defaultContactMessage,
          identityText: result.data.data.identityText || this.defaultIdentityText,
          summaryAmountLabel : result.data.data.summaryAmountLabel || amountLabel,
          summaryButtonText : result.data.data.summaryButtonText || buttonText,
          summaryFeeLabel : result.data.data.summaryFeeLabel || feeLabel,
          summaryHeader : result.data.data.summaryHeader || headerLabel,
          summaryMessage : result.data.data.summaryMessage || message,
          summaryTotalLabel : result.data.data.summaryTotalLabel || totalAmountLabel,
          paymentSuccessHeading: result.data.data.paymentSuccessHeading || "All done!",
          paymentSuccessText: result.data.data.paymentSuccessText || this.defaultPaymentSuccessText,
          paymentSubmittedHeading: result.data.data.paymentSubmittedHeading || "Nearly there …",
          paymentSubmittedText: result.data.data.paymentSubmittedText || this.defaultPaymentSubmittedText,
          upcomingPaymentReminder: {
            enableComms: this.$lodashHelper.checkObjectKey(result.data.data, "upcomingPaymentReminder.enableComms", false),
            paymentReminderDays: this.$lodashHelper.checkObjectKey(result.data.data, "upcomingPaymentReminder.paymentReminderDays", 0),
          },
          creditDefaultCommunication: {
            ...result.data.data.creditDefaultCommunication,
            automatedCreditDefaultWarning: {
              enableComms: this.$lodashHelper.checkObjectKey(result.data.data.creditDefaultCommunication, "automatedCreditDefaultWarning.enableComms", false),
              daysAfterPlanEnd: this.$lodashHelper.checkObjectKey(result.data.data.creditDefaultCommunication, "automatedCreditDefaultWarning.daysAfterPlanEnd", 0),
            },
            automatedSecurityInterestWarning: {
              enableComms: this.$lodashHelper.checkObjectKey(result.data.data.creditDefaultCommunication, "automatedSecurityInterestWarning.enableComms", false),
              daysAfterPlanEnd: this.$lodashHelper.checkObjectKey(result.data.data.creditDefaultCommunication, "automatedSecurityInterestWarning.daysAfterPlanEnd", 0),
            },
            configurableCheckIn: {
              enableComms: this.$lodashHelper.checkObjectKey(result.data.data.creditDefaultCommunication, "configurableCheckIn.enableComms", false),
              checkInDays: this.$lodashHelper.checkObjectKey(result.data.data.creditDefaultCommunication, "configurableCheckIn.checkInDays", 0),
            }
          },
          threePartyMerchantReminder: {
            enableComms: this.$lodashHelper.checkObjectKey(result.data.data, "threePartyMerchantReminder.enableComms", false),
          },
          merchantTopUp: {
            isEnabled: this.$lodashHelper.checkObjectKey(result.data.data.merchantTopUp, "isEnabled", false),
            description: this.$lodashHelper.checkObjectKey(result.data.data.merchantTopUp, "description", ""),
            feeAmount: this.$lodashHelper.checkObjectKey(result.data.data.merchantTopUp, "feeAmount", 0),
          },
          smsEnabled: this.$lodashHelper.checkObjectKey(result.data.data, "smsEnabled", true),
          allowDisbursementDateOverride: this.$lodashHelper.checkObjectKey(result.data.data, "allowDisbursementDateOverride", false),
        }, Object.keys(self.product));

        if (this.product.repaymentBy) {
          const repaymentBy = this.repaymentByList.find(item => item._id == this.product.repaymentBy);
          this.product.repaymentBy = (repaymentBy && repaymentBy._id) ? repaymentBy._id : "";
        } else {
          this.product.repaymentBy = "";
        }

        if (this.product.flipConnector) {
          this.product.flipConnector = this.product.flipConnector._id;
        }

        this.product.msf = parseFloat(self.product.msf);

        if (this.product.merchantTopUp.isEnabled) {
          this.product.merchantTopUp.feeAmount = parseFloat(self.product.merchantTopUp.feeAmount);
        }

        this.customFieldList = result.data.data.customFields.map((field) => {
          return _.omit(field, ["slug"]);
        });

        const useFromState = (this.getCurrentPaymentPlan && this.getCurrentPaymentPlan._id && (this.getCurrentPaymentPlan._id == this.$route.query.id));

        if (useFromState) {
          this.product = { ...this.getCurrentPaymentPlan };
          this.customFieldList = this.product.customFields;
        }

        this.productFieldsForExtraConditions = this.getProductFieldsForExtraConditions();
        this.emailTemplates = result.data.data.emailTemplates;
        this.smsTemplates = result.data.data.smsTemplates;
        this.parsedLegals = this.product.legals;
        this.extraConditionsList = this.product.extraConditions;

        if (result.data.data.manualAssessmentCriteria && result.data.data.manualAssessmentCriteria.length) {
          this.manualAssessmentCriteria = result.data.data.manualAssessmentCriteria;
        }

        if (useFromState && this.getCurrentManualAssessmentCriteria) {
          this.manualAssessmentCriteria = [ ...this.getCurrentManualAssessmentCriteria ];
        }

        if (useFromState && this.getCurrentExtraConditions) {
          this.extraConditionsList = [ ...this.getCurrentExtraConditions ];
        }

        if (useFromState && this.getPaymentMethod) {
          this.paymentPage = { ...this.getPaymentMethod };
        } else {
          this.paymentPage.paymentSectionHeading = result.data.data && result.data.data.paymentSectionHeading ? result.data.data.paymentSectionHeading : "";
          this.paymentPage.showPaymentMethodDescription = result.data.data && result.data.data.showPaymentMethodDescription ? result.data.data.showPaymentMethodDescription : false;
          this.paymentPage.paymentMethodDescription = result.data.data && result.data.data.paymentMethodDescription ? result.data.data.paymentMethodDescription : "";
        }

        if (this.product.integrationGuide) {
          this.integrationGuide = this.product.integrationGuide;
        }

        let disbursementData = {};

        if (result.data.data.disbursement) {
          disbursementData = result.data.data.disbursement;
        } else {
          disbursementData = this.disbursement;
        }

        if (result.data.data.disburseTo) {
          this.segmentButton.forEach((item) => item.active = (item.value == result.data.data.disburseTo));
          disbursementData.disburseTo = this.segmentButton.find((item) => item.value == result.data.data.disburseTo);
        } else {
          disbursementData.disburseTo = this.segmentButton.find(item => item.active);
        }

        if (result.data.data.disburseTo == "external") {
          this.product.isPayNow = false;
        }

        if (result.data.data.editDisbursement) {
          disbursementData.editDisbursement = result.data.data.editDisbursement;
        } else {
          disbursementData.editDisbursement = {
            customer: false,
            merchant: false,
            introducer: false,
          };
        }

        if (Object.keys(disbursementData).length > 0) {
          this.disbursement = disbursementData;
        }

        if (useFromState && this.getDisbursement) {
          this.disbursement = { ...this.getDisbursement };
          this.segmentButton.forEach((item) => { item.active = (item.value == this.disbursement.disburseTo.value) });

          if (this.disbursement.disburseTo.value == "external") {
            this.product.isPayNow = false;
          }
        }

        if (result.data.data.customPlanId) {
          this.staticProductCustomerTerms = `${process.env.VUE_APP_BASE_URL}products/${result.data.data.customPlanId}/borrower`;
        }

        if (this.isBNPO && !this.product.extension) {
          this.product.extension = defaultExtension;
        }

        if (!this.product.explainer) {
          this.product.explainer = defaultExplainer;
        }

        if (this.product.extension && this.product.extension.isEnabledExtension) {
          this.product.extension.fee = parseFloat(this.product.extension.fee);
        }

        this.planReady = true;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage("Error", ex.message, "error");
      });
    },

    truncate(num) {
      return Number(num).toFixed(3).slice(0, -1);
    },

    unsetStoreData() {
      this.UNSET_EXTRA_CONDITIONS();
      this.UNSET_CURRENT_MANUAL_ASSESSMENT_CRITERIA();
      this.UNSET_PAYMENT_METHOD_INFO();
      this.UNSET_DISBURSEMENT_INFO();
    },

    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj();
          this.unsetStoreData();
          break;
        case "save":
          this.popupActive = false;
          this.unsetStoreData();
          this.prepareSubmit();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.unsetStoreData();
          this.popupActive = false;
      }
    },

    changeDishonourFee() {
      if (!this.product.isDishonourFeeActive) {
        this.getDishonourFee();
      }
    },

    prepareSubmit() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (this.paymentPage.showPaymentMethodDescription) {
            const validatePaymentPageDescription = await this.$refs.paymentMethodEditor.validateEditor();

            if (!validatePaymentPageDescription) {
              return;
            }
          }

          if (this.product.marketingBanner) {
            const validateBannerText = await this.$refs.marketingEditor.validateEditor();

            if (!validateBannerText) {
              return;
            }
          }

          if (this.$refs.contactDetailEditor) {
            const validateContactDetail = await this.$refs.contactDetailEditor.validateEditor();

            if (!validateContactDetail) {
              return;
            }
          }

          if (this.product.requiredInformation) {
            const validateRequiredInformation = await this.$refs.requiredInformationEditor.validateEditor();

            if (!validateRequiredInformation) {
              return;
            }
          }

          if (this.$refs.identityEditor) {
            const validateIdentityText = await this.$refs.identityEditor.validateEditor();

            if (!validateIdentityText) {
              return;
            }
          }

          if (this.$refs.paymentSuccessEditor) {
            const validatePaymentSuccessText = await this.$refs.paymentSuccessEditor.validateEditor();

            if (!validatePaymentSuccessText) {
              return;
            }
          }

          if (this.product.isManualAssessment || (this.manualAssessmentCriteria && this.manualAssessmentCriteria.length)) {
            const validatePaymentSubmittedText = await this.$refs.paymentSubmittedEditor.validateEditor();

            if (!validatePaymentSubmittedText) {
              return;
            }
          }

          if (this.product.autoDebit && this.product.paymentMethods.paymentPage && this.product.paymentMethods.paymentPage.length == 0) {
            this.showToastMessage("Error", "Please add atleast one payment method", "error");
            return;
          }

          if (this.product.isDishonourFeeActive && this.product.dishonourFee == 0) {
            this.errors.add({
              field: "dishonourFee",
              msg: "Dishonour fee required."
            });

            return;
          }

          if (this.customerTermsAndConditions.length === 0) {
            this.showToastMessage("Error", "Please add atleast one customer T&C", "error");
            return;
          }

          if (this.product.isMerchantLegalActive && this.merchantTermsAndConditions.length === 0) {
            this.showToastMessage("Error", "Please add atleast one merchant T&C", "error");
            return;
          }

          if (!this.product.integrationGuide || Object.keys(this.product.integrationGuide).length === 0 ) {
            this.$vs.notify({
              title: "Error",
              text: "Please add integration guide",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
            return;
          }

          if (this.disbursement.disburseTo.value == "external" && !this.validEditDisbursement) {
            this.errors.add({
              field: "editDisbursement",
              msg: "Please select at least one disbursement account option"
            });
            return;
          }

          this.$vs.loading();
          this.product.minimumAmount = this.product.minimumAmount.toString();
          this.product.maximumAmount = this.product.maximumAmount.toString();
          this.product.maxDuration = this.product.maxDuration.toString();
          this.product.msf = this.truncate(this.product.msf);
          this.product.minimumMsfFeeAmount = this.product.minimumMsfFeeAmount.toString();
          this.product.customFields = JSON.stringify(this.customFieldList);
          this.product.legals = (typeof this.product.legals == "object") ? JSON.stringify(this.product.legals) : this.product.legals;
          this.product.extraConditions = JSON.stringify(this.extraConditionsList);
          this.product.paymentSectionHeading = this.paymentPage.paymentSectionHeading;
          this.product.showPaymentMethodDescription = this.paymentPage.showPaymentMethodDescription;
          this.product.paymentMethodDescription = this.paymentPage.paymentMethodDescription;
          this.product.requiredInformationText = this.product.requiredInformation ? this.product.requiredInformationText : "";
          this.integrationGuide = JSON.stringify(this.product.integrationGuide);

          if (this.product.productType == "B2C_BNPL" || !this.$route.query.id) {
            this.product.noOfPayments = this.product.noOfPayments.toString();
          }

          if (this.product.productType != "B2C_BNPL" || !this.$route.query.id) {
            this.product.initialPeriod = this.product.initialPeriod.toString();
          }

          const currentBank = this.settlementAccounts.find((el) => el._id === this.product.settlementAccount);

          this.product.flipConnector = currentBank.tillConnectorDetails.length > 0 ? currentBank.tillConnectorDetails[0]._id : "";

          this.customFieldList = this.customFieldList.map((el) => {
            el.slug = slugify(el.label);
            return el;
          });

          if (this.manualAssessmentCriteria.length) {
            this.product.manualAssessmentCriteria = JSON.stringify(this.manualAssessmentCriteria);
          }

          this.product.paymentMethods = (typeof this.product.paymentMethods == "object") ? JSON.stringify(this.product.paymentMethods) : this.product.paymentMethods;
          this.product.disbursement = {
            sectionHeading: this.disbursement.sectionHeading,
            showDescription: this.disbursement.showDescription,
            description: this.disbursement.description
          };

          this.product.editDisbursement = this.disbursement.editDisbursement;
          this.product.disburseTo = this.disbursement.disburseTo.value;

          if (this.displayMerchantTopUp && this.product.merchantTopUp.isEnabled) {
            this.product.merchantTopUp.feeAmount = this.product.merchantTopUp.feeAmount.toString();
          }

          if (this.$route.query.id) {
            this.updatePaymentPlan();
          } else {
            this.addPaymentPlan();
          }
        }
      });
    },

    updatePaymentPlan() {
      delete this.product._id;

      this.updatePlan({
        id: this.$route.query.id,
        data: this.product,
      }).then((result) => {
        this.$vs.loading.close();
        this.isSaved = true;
        this.$vs.notify({
          title: "Success",
          text: "Paylater product updated successfully.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.$router.push({ name: "pay-later-plan-list" });
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: ex.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },

    addExtraCondition() {
      this.$refs.productExtraConditionCollapse.maxHeight = "none !important";
      let firstBits = Math.floor(1000 + Math.random() * 9000);
      let secondBits = Math.floor(1000 + Math.random() * 9000);
      let fieldId = `EF-${firstBits}-${secondBits}`;
      this.extraConditionsList.push({
        _id: fieldId,
        criteria: "",
        maxPercentage: 0,
        productField: "",
        errorMsg: "",
      });
    },

    removeExtraCondition(index) {
      this.extraConditionsList.splice(index, 1);
    },

    resetPaymentCard() {
      this.product.amountLabel = this.defaultPaymentCardDetail.amountLabel;
      this.product.feeLabel = this.defaultPaymentCardDetail.feeLabel;
      this.product.totalAmountLabel = this.defaultPaymentCardDetail.totalAmountLabel;
      this.product.disbursementLabel = this.defaultPaymentCardDetail.disbursementLabel;
      this.product.purchaseAmountLabel = this.defaultPaymentCardDetail.purchaseAmountLabel;
      this.product.message = this.defaultPaymentCardDetail.message;
      this.product.cardTitle = this.defaultPaymentCardDetail.cardTitle;
      this.product.dateLabel = this.defaultPaymentCardDetail.dateLabel;
      this.product.marketingBanner = this.defaultPaymentCardDetail.marketingBanner;
      this.product.marketingBannerText = this.defaultPaymentCardDetail.marketingBannerText;
    },

    resetPaymentMethod() {
      this.paymentPage.paymentSectionHeading = this.defaultSectionHeading;
      this.paymentPage.showPaymentMethodDescription = false;
      this.paymentPage.paymentMethodDescription = "";
    },

    resetContactMessage() {
      this.product.contactDetailMessage = this.defaultContactMessage;
    },

    resetRequiredInformation() {
      this.product.requiredInformation = false;
      this.product.requiredInformationText = "";
    },

    resetIdentityText() {
      this.product.identityText = this.defaultIdentityText;
    },

    resetPaymentSuccessText() {
      this.product.paymentSuccessText = this.defaultPaymentSuccessText;
    },

    resetPaymentSubmittedText() {
      this.product.paymentSubmittedText = this.defaultPaymentSubmittedText;
    },

    resetPaymentSummary() {
      const {
        amountLabel,
        buttonText,
        feeLabel,
        headerLabel,
        message,
        totalAmountLabel,
      } = this.defaultPaymentCardDetail;

      this.product.summaryAmountLabel = amountLabel;
      this.product.summaryButtonText = buttonText;
      this.product.summaryFeeLabel = feeLabel;
      this.product.summaryHeader = headerLabel;
      this.product.summaryMessage = message;
      this.product.summaryTotalLabel = totalAmountLabel;

      this.errors.remove("summaryAmountLabel");
      this.errors.remove("summaryButtonText");
    },

    resetDisbursementMethod() {
      this.disbursement.sectionHeading = disbursementHeading;
      this.disbursement.showDescription = true;
      this.disbursement.description = disbursementDescription;
    },

    addPaymentPlan() {
      delete this.product._id;

      this.createPlan(this.product).then((result) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Success",
          text: "Paylater product created successfully.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.isSaved = true;
        this.$router.push({name: 'pay-later-plan-list'});
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },

    getProductFieldsForExtraConditions() {
      let conditions = [];
      conditions = this.customFieldList
        .filter((element) => {
          return element.type == "currency";
        })
        .map((item) => {
          return { text: item.label, value: item.customFieldId };
        });
      return conditions;
    },

    handleTemplateEdit(id, templateType) {
      this.product.customFields = this.customFieldList;
      this.SET_CURRENT_PLAN(this.product);
      this.SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(this.manualAssessmentCriteria);
      this.SET_EXTRA_CONDITIONS(this.extraConditionsList);
      this.SET_PAYMENT_METHOD_INFO(this.paymentPage);
      this.SET_DISBURSEMENT_INFO(this.disbursement);

      if (templateType === "Email") {
        return this.$router.push({
          name: "email-templates-edit",
          params: { id: id },
          query:{planId:this.$route.query.id, productType:this.$route.query.productType}
        });
      }

      if (templateType === "SMS") {
        return this.$router.push({
          name: "sms-templates-edit",
          params: { id: id },
          query:{planId:this.$route.query.id, productType:this.$route.query.productType}
        });
      }
    },

    handleIntegrationGuideEdit() {
      this.product.customFields = this.customFieldList;
      this.SET_CURRENT_PLAN(this.product);
      this.SET_CURRENT_MANUAL_ASSESSMENT_CRITERIA(this.manualAssessmentCriteria);
      this.SET_EXTRA_CONDITIONS(this.extraConditionsList);
      this.SET_PAYMENT_METHOD_INFO(this.paymentPage);
      this.SET_DISBURSEMENT_INFO(this.disbursement);

      let query = {};
      query.action = "edit";
      query.productType = this.$route.query.productType;

      if (this.$route.query.id) {
        query.id = this.$route.query.id;
      }
      return this.$router.push({ name: "integration-guide", query: query });
    },

    addManualAssessmentCriteria() {
      this.manualAssessmentCriteria.push(JSON.parse(JSON.stringify(this.criteriaItem)));
    },

    removeManualAssessmentCriteria(index) {
      this.manualAssessmentCriteria.splice(index, 1);
    },

    getDropdownData(label) {
      const data = this.customFieldList.find(item => item.label == label);
      return data ? data.dropdownSelectFields : [];
    },

    getBnplDuration(frequency) {
      let paymentFrequency = 1;
        switch(frequency) {
          case "WEEKLY":
            paymentFrequency = 7;
            break;
          case "FORTNIGHTLY":
            paymentFrequency = 14;
            break;
          case "MONTHLY":
            paymentFrequency = 30;
            break;
        };
      return paymentFrequency;
    },

    changeProductConfig(type) {
      if (!this.productEdit) {
        this.product.productConfigType = type;

        if (this.product.productConfigType == "TWO_PARTY") {
          this.product.showTransactionBalance = true;
          this.product.autoDebit = true;
          this.product.isPayNow = false;
        } else {
          this.product.showTransactionBalance = false;
          this.product.merchantTopUp = {
            isEnabled: false,
            description: "",
            feeAmount: 0
          };
        }

        this.changePaymentMethods();
      }
    },

    changePaymentMethods() {
      if (this.product.autoDebit) {
        if (this.product.productConfigType == "THREE_PARTY") {
          this.product.paymentMethods = {
            paymentPage: ["CARD", "DIRECT_DEBIT"],
            customerPortal: ["CARD", "DIRECT_DEBIT", "EFT"]
          };
        } else if (this.product.productConfigType == "TWO_PARTY") {
          this.product.paymentMethods = {
            paymentPage: ["DIRECT_DEBIT"],
            customerPortal: ["DIRECT_DEBIT"]
          };
        }
      } else {
        this.product.paymentMethods = {
          paymentPage: [],
          customerPortal: ["EFT"]
        };
        if (this.errors.has("paymentMethod")) {
          this.errors.remove("paymentMethod");
        }
      }
    },

    validatePaymentMethods(key) {
      if (this.product.paymentMethods.paymentPage.includes(key)) {
        if (!this.product.paymentMethods.customerPortal.includes(key)) {
          this.product.paymentMethods.customerPortal.push(key);
        }
      } else {
        this.product.paymentMethods.customerPortal = this.product.paymentMethods.customerPortal.filter((item) => item != key);
      }

      if (this.product.autoDebit && this.product.paymentMethods.paymentPage && this.product.paymentMethods.paymentPage.length == 0) {
        this.errors.add({
          field: "paymentMethod",
          msg: "Please select at least one payment method"
        });
      } else {
        if (this.errors.has(`paymentMethod`)) {
          this.errors.remove(`paymentMethod`);
        }
      }
    },

    async getDishonourFee() {
      this.$vs.loading();

      await this.fetchSetting("dishonourFee").then((result) => {
        this.product.dishonourFee = result.data.data.rawAmount;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage(ex.response.data.title, ex.response.data.message, "error");
      });
    },

    async getRepaymentBy() {
      await this.fetchSetting("repaymentBy").then((result) => {
        this.repaymentByList = result.data.data.filter(item => item.active);
      }).catch((ex) => {
        this.showToastMessage(ex.response.data.title, ex.response.data.message, "error");
      });
    },

    checkLegalId(id) {
      return (id && id.includes("LG")) ? false : true;
    },

    formatIntegrationDate(date) {
      return moment(date).format("DD/MM/YYYY - hh:mm:ss");
    },

    addText(val, label, editor) {
      const matches = this.product[label].match(/{{(.+?)}}/g);

      if (!matches || !matches.includes(val)) {
        this.insertText(val, label,editor);
      }
    },

    parseContent(data) {
      if (!data) {
        return "";
      }

      data = data.split("{{Fee %}}").join(this.product.msf + "%");
      data = data.split("{{Fee $ amount}}").join("$"+this.addZeroes(this.calculateFees));

      return data;
    },

    insertText(text, label, editor) {
      const el = this.$refs[editor].$el.querySelector("textarea");
      let cursorPos = el.selectionEnd; // Get current Position
      this.product[label] = this.product[label].substring(0, cursorPos) + text + this.product[label].substring(cursorPos);
      cursorPos += this.product[label].length; // Get new cursor position

      // Wait until vue finishes rendering the new text and set the cursor position.
      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));
    },

    validateDisbursement() {
      const isExternal = (this.disbursement.disburseTo.value == "external");

      if (isExternal && this.validEditDisbursement && this.errors.has("editDisbursement")) {
        this.errors.remove("editDisbursement");
      } else if (isExternal && !this.validEditDisbursement) {
        this.errors.add({
          field: "editDisbursement",
          msg: "Please select at least one disbursement account option"
        });
      }
    },

    changeExtension() {
      if (this.product.extension.isEnabledExtension) {
        this.product.extension.frequency = "MONTHLY";
      } else {
        this.product.extension.frequency = null;
        this.product.extension.maxOccurrences = null;
        this.product.extension.startDaysAfter = null;
        this.product.extension.fee = 0;
        this.product.configurableFeeText = "";
        this.product.summaryConfigurableFeeText = "";
      }
    },

    changeExplainer() {
      if (this.product.explainer.isEnabled) {
        this.product.explainer.buttonText = "I understand, proceed";
      } else {
        this.product.explainer.header = "";
        this.product.explainer.content = "";
        this.product.explainer.buttonText = "";
      }
    },

    isNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.deleteProduct();
          break;
        case "cancel":
          this.popupDelActive = false;
          break;
        default:
          this.popupDelActive = false;
      }
    },

    async deleteProduct() {
      this.$vs.loading();
      this.deletePlan(this.$route.query.id).then((result) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Payment plan",
          text: "Payment plan deleted successfully",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.$router.push({ name: "pay-later-plan-list" });
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: ex.data.title,
          text: ex.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },
  },

  async mounted() {
    await this.checkAdminLogin();

    Validator.extend("greaterThan", {
      getMessage: (field) => `The maximum amount should be greater than minimum amount.`,
      validate: (value) => parseFloat(value) > parseFloat(this.product.minimumAmount),
    });

    Validator.extend("htmlCharLimit", {
      validate: (value, limit) => {
        const strippedValue = value.replace(/<[^>]+>/g, '');
        return strippedValue.length <= limit;
      },
    });

    this.disbursement.disburseTo = this.segmentButton.find(item => item.active);

    if (this.$refs.legalsCollapse.maxHeight) {
      this.$refs.legalsCollapse.maxHeight = "none !important";
    }

    if (["B2C_BNPO", "B2C_BNPO_NC"].includes(this.$route.query.productType)) {
      this.product.cardTitle = "Your agreement";
      this.isBNPO = true;
    } else {
      this.isBNPO = false;
      this.product.cardTitle = "Your payment";
    }

    if (this.$route.query.productType) {
      this.product.productType = this.$route.query.productType;
    }

    this.product.maxDuration = 0;
    await this.getAllSettlementAccounts();
    await this.getRepaymentBy();
    let useFromState = false;

    if (this.$route.query.id) {
      this.productEdit = true;
      await this.getPaymentPlanById(this.$route.query.id);
    } else {
      if (!this.$route.query.type && !this.product.dishonourFee) {
        this.getDishonourFee();
      }

      this.product.contactDetailMessage = this.defaultContactMessage;
      this.product.identityText = this.defaultIdentityText;
      this.planReady = true;

      useFromState = (this.getCurrentPaymentPlan && (this.getCurrentPaymentPlan._id == ""));

      if (useFromState) {
        if (this.getCurrentManualAssessmentCriteria) {
          this.manualAssessmentCriteria = [ ...this.getCurrentManualAssessmentCriteria ];
        }

        if (this.getCurrentExtraConditions) {
          this.extraConditionsList = [ ...this.getCurrentExtraConditions ];
        }

        if (this.getPaymentMethod) {
          this.paymentPage = { ...this.getPaymentMethod };
        }

        if (this.getDisbursement) {
          this.disbursement = { ...this.getDisbursement };
          this.segmentButton.forEach((item) => { item.active = (item.value == this.disbursement.disburseTo.value) });
        }
      }
    }

    if (useFromState && this.getCurrentPaymentPlan && (this.$route.query.legal || this.$route.query.integrationGuide)) {
      this.product = { ...this.getCurrentPaymentPlan };
      this.parsedLegals = this.product.legals;
      this.customFieldList = this.product.customFields;

      if (this.product.integrationGuide) {
        this.integrationGuide = this.product.integrationGuide;
      }

      if (this.$route.query.legal === true) {
        const el = document.getElementById("legals");
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      } else if (this.$route.query.integrationGuide === true) {
        const integrationElement = document.getElementById("integrationBox");
        integrationElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else if (!this.getCurrentPaymentPlan && !this.$route.query.id) {
      this.resetPaymentSummary();
      this.product.paymentSuccessHeading = "All done!";
      this.product.paymentSuccessText = this.defaultPaymentSuccessText;
      this.product.paymentSubmittedHeading = "Nearly there …";
      this.product.paymentSubmittedText = this.defaultPaymentSubmittedText;
    }

    if (this.$route.query.template) {
      const el = document.getElementById("email-template");
      el.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },

  beforeRouteLeave(to, from, next) {
    if (!["email-templates-edit", "integration-guide", "pay-later-legals", "sms-templates-edit"].includes(to.name)) {
      this.unsetStoreData();
    }
    next();
  },

  updated() {
    this.auto_grow("custom-textarea");
  },

  watch: {
    "product.initialPeriod": function (newVal, _oldVal) {
      if (["B2C_BNPO", "B2C_BNPO_NC"].includes(this.product.productType)) {
        this.product.maxDuration = newVal;
      }
    },
    "product.paymentFrequency": function () {
      if (["B2C_BNPL"].includes(this.product.productType)) {
        this.product.maxDuration = this.getBnplDuration(this.product.paymentFrequency) * this.product.noOfPayments;
      }
    },
    "product.noOfPayments": function () {
      if (["B2C_BNPL"].includes(this.product.productType)) {
        this.product.maxDuration = this.getBnplDuration(this.product.paymentFrequency) * this.product.noOfPayments;
      }
    },
    "product.autoDebit": function () {
      this.changePaymentMethods();
    },
    "paymentPage.showPaymentMethodDescription": function(val) {
      if (!val) {
        this.paymentPage.paymentMethodDescription = "";
      }
    },
    "product.paymentMethods.paymentPage" : function(val) {
      if (["DIRECT_DEBIT"].includes(val)) {
        this.product.isDishonourFeeActive = true;
      }
    },
    "product.creditDefaultCommunication.automatedCreditDefaultWarning.enableComms": function () {
      if (this.product.creditDefaultCommunication.automatedCreditDefaultWarning.enableComms) {
        if (!this.product.creditDefaultCommunication.automatedCreditDefaultWarning.daysAfterPlanEnd) {
          this.product.creditDefaultCommunication.automatedCreditDefaultWarning.daysAfterPlanEnd = 14;
        }
      } else {
        this.product.creditDefaultCommunication.automatedCreditDefaultWarning.daysAfterPlanEnd = 0;
      }
    },
    "product.creditDefaultCommunication.configurableCheckIn.enableComms": function () {
      if (this.product.creditDefaultCommunication.configurableCheckIn.enableComms) {
        if (!this.product.creditDefaultCommunication.configurableCheckIn.checkInDays) {
          this.product.creditDefaultCommunication.configurableCheckIn.checkInDays = 28;
        }
      } else {
        this.product.creditDefaultCommunication.configurableCheckIn.checkInDays = 0;
      }
    },
    "product.upcomingPaymentReminder.enableComms": function () {
      if (this.product.upcomingPaymentReminder.enableComms) {
        if (!this.product.upcomingPaymentReminder.paymentReminderDays) {
          this.product.upcomingPaymentReminder.paymentReminderDays = 7;
        }
      } else {
        this.product.upcomingPaymentReminder.paymentReminderDays = 0;
      }
    },
    "product.creditDefaultCommunication.automatedSecurityInterestWarning.enableComms": function () {
      if (this.product.creditDefaultCommunication.automatedSecurityInterestWarning.enableComms) {
        if (!this.product.creditDefaultCommunication.automatedSecurityInterestWarning.daysAfterPlanEnd) {
          this.product.creditDefaultCommunication.automatedSecurityInterestWarning.daysAfterPlanEnd = 14;
        }
      } else {
        this.product.creditDefaultCommunication.automatedSecurityInterestWarning.daysAfterPlanEnd = 0;
      }
    },
    "product.productConfigType": function () {
      if (this.product.productConfigType == "TWO_PARTY") {
        this.product.editDisbursement.customer = false;
      }
    },
    "product.merchantTopUp.isEnabled": function () {
      if (this.product.merchantTopUp.isEnabled && this.product.merchantTopUp.feeAmount == 0) {
        this.product.merchantTopUp.feeAmount = 40;
      } else if (!this.product.merchantTopUp.isEnabled) {
        this.product.merchantTopUp.description = "";
        this.product.merchantTopUp.feeAmount = 0;
      }
    },
    "product.showTransactionBalance": function () {
      if (!this.product.showTransactionBalance && this.product.merchantTopUp && this.product.merchantTopUp.isEnabled) {
        this.product.merchantTopUp = {
          isEnabled: false,
          description: "",
          feeAmount: 0
        };
      }
    }
  },

  created() {
    this.$validator.extend("phoneFormat", {
      getMessage: () => "The contact phone format is invalid.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
        ) !== null,
    });
  }
};
</script>

